<template>
  <div>
    <h2>{{ $t("userManagementUserListTitle") }}</h2>
    <v-data-table
      :disable-pagination="true"
      :disable-sort="true"
      :hide-default-footer="true"
      :loading="isLoading"
      :loading-text="$t('loading')"
      :no-data-text="$t('userManagementUserListErrorMessage')"
      :headers="headers"
      :items="users"
    >
      <template v-slot:item.fullName="{ item }">
        {{ fullName(item) }}
      </template>
      <template v-slot:item.email="{ item }">
        {{ email(item) }}
      </template>
      <template v-slot:item.creationDate="{ item }">
        {{ userCreationDate(item) }}
      </template>
      <template v-slot:item.permissions="{ item }">
        <UserPermissionGroups v-bind:permissions="item.permissions" />
      </template>
      <template v-slot:item.actions="{ item }">
        <CDFlyoutMenu
          v-if="hasOrderDeskPermission"
          :left="true"
          menu-icon="$more"
          color="primary"
        >
          <CDFlyoutMenuItem
            name="editPermissionGroups"
            icon="$edit"
            :title="$t('userManagementEditPermissionGroups')"
            @cdClick="editPermissionGroupsClicked(item)"
          />
          <CDFlyoutMenuItem
            name="deleteUser"
            icon="$trash"
            :title="$t('userManagementUserDeletion')"
            @cdClick="openConfirmationModalForDeletion(item)"
          />
        </CDFlyoutMenu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { orderDeskContact, permissionNames } from "@/utils/enums";
import { dateMixin } from "@/utils/mixins";
import UserPermissionGroups from "@/views/Components/UserPermissionGroups";
import { VDataTable } from "vuetify/lib";
import ConfirmationModal from "@/views/Modals/ConfirmationModal";

export default {
  name: "Users",
  props: ["isLoading", "users"],
  components: {
    UserPermissionGroups,
    VDataTable,
  },
  mixins: [dateMixin],
  data() {
    return {
      headers: [
        {
          text: this.$t("userManagementUsersName"),
          value: "fullName",
          cellClass: "fullName",
        },
        {
          text: this.$t("userManagementUsersEmail"),
          value: "email",
          cellClass: "email",
        },
        {
          text: this.$t("userManagementUsersDate"),
          value: "creationDate",
          cellClass: "creationDate text-no-wrap",
        },
        {
          text: this.$t("userManagementUsersGroups"),
          value: "permissions",
          cellClass: "permissions",
        },
        {
          text: "",
          value: "actions",
          align: "end",
          cellClass: "text-right",
        },
      ],
    };
  },
  methods: {
    hasOrderDeskPermission(user) {
      return user.permissions[permissionNames.ORDER_DESK_MANAGER];
    },
    fullName(user) {
      return this.hasOrderDeskPermission(user)
        ? orderDeskContact.NAME
        : `${user.lastName}, ${user.firstName}`;
    },
    email(user) {
      return this.hasOrderDeskPermission(user)
        ? orderDeskContact.EMAIL
        : user.email;
    },
    userCreationDate(user) {
      const date = new Date(user.creationDate);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString(undefined, options);
    },
    editPermissionGroupsClicked(user) {
      this.$emit("editPermissionGroupsClicked", user);
    },
    openConfirmationModalForDeletion(user) {
      this.$modal.show(
        ConfirmationModal,
        {
          title: "userManagementUserDeletionConfirmationTitle",
          translatedTextParagraphs: [
            this.$t("userManagementUserDeletionConfirmationText", {
              user: `${user.firstName} ${user.lastName} (${user.email})`,
            }),
          ],
          warning: "userManagementUserDeletionConfirmationWarning",
          confirmClickedFunction: () => this.deleteUserClicked(user),
        },
        {
          height: "auto",
          width: "1200px",
          clickToClose: false,
        }
      );
    },
    deleteUserClicked(user) {
      this.$emit("deleteUserClicked", user, false);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 24px;
}

::v-deep .v-data-table {
  .v-data-table__wrapper table {
    thead {
      tr {
        th {
          color: var(--azena-black) !important;
        }
      }
    }
  }
}
</style>
