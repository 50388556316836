const config = () => {
  const hostname = window && window.location && window.location.hostname;
  const isSandbox = hostname.includes("sandbox.securityandsafetydev.com");
  const isDev = hostname.includes("camdroid.io");
  const isDemo = hostname.includes("securityandsafetydemo.io");
  const isLive = hostname.includes("azena.com");

  let env = "localhost";
  if (isSandbox) {
    env = "sandbox";
  } else if (isDev) {
    env = "dev";
  } else if (isDemo) {
    env = "demo";
  } else if (isLive) {
    env = "live";
  }

  return {
    env,
  };
};

export default config;
