<template>
  <div class="message-box">
    <CDNotificationToastWrapper :x-position="10" :y-position="20">
      <div v-for="(message, index) in messageTranslated" :key="index">
        <CDNotificationToast :type="message.type" :value="true">
          {{ message.text }}
        </CDNotificationToast>
      </div>
    </CDNotificationToastWrapper>
  </div>
</template>

<script>
import { commonActionTypes } from "@/store/actionTypes";
import { mapState } from "vuex";
import { translatedMessages } from "@/utils/mixins";

export default {
  name: "MessageBox",
  mixins: [translatedMessages],
  data() {
    return {
      expiryTime: process.env.VUE_APP_MSG_DISPLAY_TIME || 6000,
      toasts: [],
    };
  },
  computed: {
    ...mapState({
      isLoading(state, getters) {
        return getters["isLoading"];
      },
    }),
    ...mapState({
      messages(state, getters) {
        return getters["getMessages"];
      },
    }),
    messageTranslated() {
      return this.translatedMessages(this.messages);
    },
  },
  watch: {
    messages: function (messages) {
      messages.forEach((message) => {
        if (!this.toasts.includes(message.index)) {
          this.toasts.push(message.index);
          setTimeout(() => {
            this.$store.dispatch(commonActionTypes.POP_FIRST_MESSAGE_OBJ);
            this.toasts = this.toasts.filter((item) => item !== message.index);
          }, parseInt(this.expiryTime));
        }
      });
    },
  },
};
</script>
