<template>
  <CDButtonTextIcon
    icon="$copy"
    iconposition="right"
    class="companyId"
    @click="copyCompanyId"
  >
    {{ company.companyId }}
  </CDButtonTextIcon>
</template>

<script>
import { ECOSYSTEM_NAMESPACE } from "@/store/namespaces";
import { ecosystemActionTypes } from "@/store/actionTypes";

export default {
  name: "CompanyId",
  props: ["company"],
  methods: {
    copyCompanyId() {
      let copied;
      try {
        navigator.clipboard.writeText(this.company.companyId);
        copied = true;
      } catch (err) {
        copied = false;
      }

      return this.$store.dispatch(
        ECOSYSTEM_NAMESPACE + "/" + ecosystemActionTypes.COPY_COMPANY_ID,
        { copied }
      );
    },
  },
};
</script>

<style lang="scss">
.companyId {
  margin-left: -8px;
}
</style>
