const uuidv1 = require("uuid/v1");
import ecosystem from "./modules/ecosystem";
import editUser from "./modules/editUserAndInvitation";
import account from "./modules/account";

import { commonActionTypes } from "./actionTypes";
import { commonMutationTypes } from "./mutationTypes";
import api from "@/store/api";
import processError from "@/store/utils/processError";
import { commitMessage } from "@/store/utils/commit";
import { sortCountries } from "@/store/utils/sorting";
const { POP_FIRST_MESSAGE_OBJ, IS_LOADING, LOAD_COUNTRIES } = commonActionTypes;
import featureFlags from "../../../shared-ui-libs/src/featureFlags";

const {
  ADD_MESSAGE_OBJ,
  REMOVE_FIRST_MESSAGE_OBJ,
  LOAD_COUNTRIES_SUCCESS,
  API_ERROR,
} = commonMutationTypes;

const resetState = () => {
  return {
    isLoading: false,
    messagesList: [],
    countries: [],
    featureFlags,
  };
};

const storeObject = {
  namespaced: true,

  state: {
    ...resetState(),
  },

  actions: {
    [POP_FIRST_MESSAGE_OBJ]({ commit, state }) {
      if (state.messagesList.length > 0) {
        commit(REMOVE_FIRST_MESSAGE_OBJ);
      }
      return Promise.resolve();
    },
    [LOAD_COUNTRIES]({ commit }) {
      return api
        .getCountries()
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            commit(LOAD_COUNTRIES_SUCCESS, response.data);
          }
        })
        .catch((err) => {
          const data = processError(err);
          commit(API_ERROR, data);
          commitMessage(commit, data["messages"]);
          throw err;
        });
    },
  },

  getters: {
    getMessages: (state) => {
      return state.messagesList;
    },

    isLoading: (state) => {
      return state.isLoading;
    },

    countries: (state) => {
      return state.countries;
    },

    featureFlags: (state) => {
      return state.featureFlags;
    },
  },

  mutations: {
    [ADD_MESSAGE_OBJ](state, messageObj) {
      messageObj.index = uuidv1();
      state.messagesList.push(messageObj);
    },
    [REMOVE_FIRST_MESSAGE_OBJ](state) {
      state.messagesList.splice(0, 1);
    },
    [IS_LOADING](state, payload) {
      state.isLoading = payload;
    },
    [LOAD_COUNTRIES_SUCCESS](state, countries) {
      state.countries = sortCountries(countries);
    },
  },

  // improve: register only modules in the store when they are really needed, see dynamic registration
  modules: {
    account,
    editUser,
    ecosystem,
  },
};

export { storeObject, resetState };
