<template>
  <v-container fill-height>
    <div class="error-container">
      <div>
        <h1>{{ $t(errorTitle) }}</h1>
        <p>
          {{ $t(errorDescription) }}
          <CDButtonText
            color="primary"
            :href="sastLinks.SUPPORT_URL"
            target="_blank"
          >
            {{ $t("error.contactUs") }}
          </CDButtonText>
        </p>
      </div>
      <img v-bind:src="imageSrc" v-bind:alt="statusCode" />
    </div>
  </v-container>
</template>

<script>
import { sastLinks } from "@/utils/enums";
import { VContainer } from "vuetify/lib";

export default {
  name: "Error",
  components: { VContainer },
  props: {
    statusCode: Number,
  },
  data() {
    return {
      sastLinks,
      predefiniedErrorTexts: [403, 404, 500],
      predefiniedErrorImages: [404],
    };
  },
  computed: {
    imageSrc() {
      const image = this.predefiniedErrorImages.includes(this.statusCode)
        ? this.statusCode
        : "default";
      return require(`../assets/error-${image}.svg`);
    },
    errorTitle() {
      return `error.${this.filteredCode}.title`;
    },
    errorDescription() {
      return `error.${this.filteredCode}.description`;
    },
    filteredCode() {
      return this.predefiniedErrorTexts.includes(this.statusCode)
        ? this.statusCode
        : "default";
    },
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}
</style>
