<template>
  <div class="groups" v-bind:title="permissionsToString">
    {{ permissionsToTranslatedString }}
  </div>
</template>
<script>
export default {
  name: "UserPermissionGroups",
  props: ["permissions"],
  computed: {
    permissionsToTranslatedString() {
      return this.filterPermissions()
        .map((permission) =>
          this.$t(
            "userManagement" +
              permission.charAt(0).toUpperCase() +
              permission.slice(1)
          )
        )
        .join(", ");
    },
    permissionsToString() {
      return this.filterPermissions().join(", ");
    },
  },
  methods: {
    filterPermissions() {
      return Object.keys(this.permissions)
        .filter((permission) => this.permissions[permission])
        .filter(
          (permission) =>
            !["isIntegrator", "isDeveloper", "isManufacturer"].includes(
              permission
            )
        );
    },
  },
};
</script>
