/*
 * purpose of this module is to avoid code duplication by defining mutations used by different modules
 */

import { commonMutationTypes } from "./mutationTypes";
import assignDeep from "assign-deep";

const { API_ERROR, SET_DATA } = commonMutationTypes;

const commonMutations = {
  [SET_DATA](state, payload) {
    assignDeep(state, payload);
  },
  [API_ERROR](state, data) {
    state.errors = data["errors"];
  },
};

export { commonMutations };
