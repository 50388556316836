import api from "../api";
import processError from "../utils/processError";
import { buildAndCommitMessage, commitMessage } from "../utils/commit";
import { commonMutationTypes, ecosystemMutationTypes } from "../mutationTypes";
import { commonMutations } from "../commonMutations";
import { ecosystemActionTypes } from "../actionTypes";
import { sortCompanies, sortInvitations, sortUsers } from "../utils/sorting";

const {
  INVITE_NEW_USER,
  SEND_INVITATION_TO_BACKEND,
  GET_USERS_FOR_COMPANY,
  GET_INVITATIONS_FOR_COMPANY,
  DELETE_COMPANY,
  SELECT_COMPANY,
  UPDATE_COMPANY_PERMISSIONS,
  UPDATE_COMPANY_FRIENDLY_NAME,
  UPDATE_COMPANY_PRE_APPROVED_APPS,
  UPDATE_APP_PURCHASE_EMAIL,
  UPDATE_APP_SALES_EMAIL,
  COPY_COMPANY_ID,
  ADD_SELLER_EMAIL,
} = ecosystemActionTypes;

const {
  SET_COMPANIES,
  SET_USERS,
  SET_USER,
  SET_COMPANY,
  SET_INVITATIONS,
  REMOVE_SINGLE_USER,
  REMOVE_SINGLE_INVITATION,
  SET_COMPANY_INFORMATION,
} = ecosystemMutationTypes;

const { API_ERROR, SET_DATA, ADD_MESSAGE_OBJ, RESET_STATE } =
  commonMutationTypes;

const state = () => ({
  companies: [],
  ...resetState,
});

const resetAddressState = () => ({
  street: "",
  houseNumber: "",
  postalCode: "",
  city: "",
});

const resetPjecoCompanyInfo = () => ({
  bpmdId: "",
  dcsId: "",
});

const resetSavedCompanyInformation = () => ({
  phone: "",
  companyStatus: "",
  companyName: "",
  companyTypes: [],
  country: "",
  taxId: "",
  businessAddress: resetAddressState(),
  billingAddress: resetAddressState(),
  pjecoCompanyInfo: resetPjecoCompanyInfo(),
});

const resetState = {
  companyId: "",
  savedCompanyInformation: resetSavedCompanyInformation(),
  users: [],
  user: undefined,
  company: undefined,
  invitations: [],
  errors: {},
};

const mutations = {
  [SET_COMPANIES](state, data) {
    state.companies = sortCompanies(data);
  },
  [SET_USERS](state, data) {
    state.users = sortUsers(data);
  },
  [SET_USER](state, data) {
    state.user = data;
  },
  [SET_COMPANY](state, data) {
    state.company = data;
  },
  [SET_INVITATIONS](state, data) {
    state.invitations = sortInvitations(data);
  },
  [REMOVE_SINGLE_USER](state, data) {
    const index = state.users.indexOf(data);
    state.users.splice(index, 1);
  },
  [REMOVE_SINGLE_INVITATION](state, data) {
    const index = state.invitations.indexOf(data);
    state.invitations.splice(index, 1);
  },
  [SET_COMPANY_INFORMATION](state, data) {
    if (!data.businessAddress) {
      data.businessAddress = resetAddressState();
    }
    if (!data.billingAddress) {
      data.billingAddress = resetAddressState();
    }
    if (!data.pjecoCompanyInfo) {
      data.pjecoCompanyInfo = resetPjecoCompanyInfo();
    }
    Object.assign(state.savedCompanyInformation, data);
  },
  [RESET_STATE](state) {
    if (!state.companyId) {
      Object.assign(
        state.savedCompanyInformation,
        resetSavedCompanyInformation()
      );
    }
  },
  ...commonMutations,
};

const actions = {
  async [SELECT_COMPANY]({ commit, dispatch }, companyId) {
    commit(SET_DATA, { companyId: companyId });
    return Promise.all([
      dispatch(GET_USERS_FOR_COMPANY),
      dispatch(GET_INVITATIONS_FOR_COMPANY),
    ]);
  },
  async [GET_USERS_FOR_COMPANY]({ commit, state }) {
    return api
      .getUsersForCompany(state.companyId)
      .then((response) => {
        commit(SET_USERS, response.data);
      })
      .catch((err) => {
        const data = processError(err);
        commit(API_ERROR, data);
        commitMessage(commit, data["messages"]);
      });
  },
  async [GET_INVITATIONS_FOR_COMPANY]({ commit, state }) {
    return api
      .getInvitationsForCompany(state.companyId)
      .then((response) => {
        commit(SET_INVITATIONS, response.data);
      })
      .catch((err) => {
        const data = processError(err);
        commit(API_ERROR, data);
        commitMessage(commit, data["messages"]);
      });
  },
  async [DELETE_COMPANY]({ commit }, companyId) {
    return api
      .deleteCompany(companyId)
      .then(() => {
        const messages = {
          error: [],
          success: ["deleteCompanySuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch((err) => {
        const data = processError(err, "deleteCompanyFail");
        buildAndCommitMessage(commit, data);
      });
  },
  [INVITE_NEW_USER]({ commit, state, dispatch }, payload) {
    const inviteeEmail = payload.inviteeEmail;
    // Check if invitee is already in company
    if (state.users.some((user) => inviteeEmail === user.email)) {
      return Promise.resolve().then(() => {
        commit(
          ADD_MESSAGE_OBJ,
          {
            error: ["userManagementInvitationFailAlreadyInCompany"],
            success: [],
          },
          { root: true }
        );
      });
    }
    return dispatch(SEND_INVITATION_TO_BACKEND, {
      inviteeEmail: inviteeEmail,
      inviteePermissions: payload.inviteePermissions,
      inviteeCompanyId: payload.inviteeCompanyId,
    });
  },
  async [UPDATE_COMPANY_PERMISSIONS]({ commit }, { companyId, permissions }) {
    return api
      .updateCompanyPermissions(
        companyId,
        permissions.isManufacturerPortalEnabled,
        permissions.isPurchaseEnabled,
        permissions.isSalesEnabled,
        permissions.isDmpApiEnabled
      )
      .then(() => {
        const messages = {
          error: [],
          success: ["saveCompanyPermissionsSuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch(() => {
        commitMessage(commit, {
          error: ["saveCompanyPermissionsFail"],
          success: [],
        });
      });
  },
  async [UPDATE_COMPANY_FRIENDLY_NAME](
    { commit },
    { companyId, friendlyName }
  ) {
    return api
      .updateCompanyFriendlyName(companyId, friendlyName)
      .then(() => {
        const messages = {
          error: [],
          success: ["updateCompanyFriendlyNameSuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch(() => {
        commitMessage(commit, {
          error: ["updateCompanyFriendlyNameFail"],
          success: [],
        });
      });
  },
  async [ADD_SELLER_EMAIL]({ commit }, { companyId, sellerEmail }) {
    return api
      .addSellerEmail(companyId, sellerEmail)
      .then(() => {
        const messages = {
          error: [],
          success: ["updateSellerEmailSuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch(() => {
        commitMessage(commit, {
          error: ["updateSellerEmailFail"],
          success: [],
        });
      });
  },
  async [UPDATE_COMPANY_PRE_APPROVED_APPS]({ commit }, { companyId, enabled }) {
    return api
      .togglePreApprovedAppsFeature(companyId, enabled)
      .then(() => {
        const messages = {
          error: [],
          success: ["updateCompanyPreApprovedAppsSuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch(() => {
        commitMessage(commit, {
          error: ["updateCompanyPreApprovedAppsFail"],
          success: [],
        });
      });
  },
  async [UPDATE_APP_PURCHASE_EMAIL]({ commit }, { companyId, email }) {
    return api
      .updateAppPurchaseEmail(companyId, email)
      .then(() => {
        const messages = {
          error: [],
          success: ["updateAppPurchaseEmailSuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch(() => {
        commitMessage(commit, {
          error: ["updateAppPurchaseEmailFail"],
          success: [],
        });
      });
  },
  async [UPDATE_APP_SALES_EMAIL]({ commit }, { companyId, email }) {
    return api
      .updateAppSalesEmail(companyId, email)
      .then(() => {
        const messages = {
          error: [],
          success: ["updateAppSalesEmailSuccess"],
        };
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      })
      .catch(() => {
        commitMessage(commit, {
          error: ["updateAppSalesEmailFail"],
          success: [],
        });
      });
  },
  async [COPY_COMPANY_ID]({ commit }, { copied }) {
    const messages = {
      error: copied ? [] : ["copyCompanyIdFail"],
      success: copied ? ["copyCompanyIdSuccess"] : [],
    };
    commit(ADD_MESSAGE_OBJ, messages, { root: true });
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
