import api from "../api";
import processError from "../utils/processError";
import { commitMessage } from "../utils/commit";
import { accountActionTypes } from "../actionTypes";
import { accountMutationTypes, commonMutationTypes } from "../mutationTypes";
import { commonMutations } from "../commonMutations";
import company from "./accountCompany";

const state = () => ({
  email: "", // email cannot be mutated and is for read-only purposes part of the state
  fullName: "",
  isDeveloper: "",
  isIntegrator: "",
  canViewEcosystemCompanies: "",
  canEditEcosystemUsers: "",
  canEditEcosystemCompanies: "",
  canManageCompanyDetails: "",
  canManageCompanyUsers: "",
  canDeleteEcosystemCompanies: "",
  canBypassPjecoScreening: "",
  canInviteCompanyUsers: "",
  ...resetState,
});

const resetState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  displaySite: true,
  errors: {},
};

const {
  FETCH_ACCOUNT_USER,
  UPDATE_ACCOUNT_USER,
  GET_PERMISSIONS_FROM_TOKEN,
  DELETE_USER,
} = accountActionTypes;
const { FETCH_ACCOUNT_USER_SUCCESS, UPDATE_ACCOUNT_USER_SUCCESS } =
  accountMutationTypes;
const { SET_DATA, API_ERROR, ADD_MESSAGE_OBJ } = commonMutationTypes;

const updateFullname = (state) => {
  state.fullName = `${state.firstName} ${state.lastName}`;
};

const mutations = {
  [UPDATE_ACCOUNT_USER_SUCCESS](state, data) {
    Object.assign(state, resetState); // reset state, so that state is then consistent with data object in the next step
    Object.assign(state, data);
    updateFullname(state);
  },
  [FETCH_ACCOUNT_USER_SUCCESS](state, payload) {
    Object.assign(state, payload);
    updateFullname(state);
  },
  // reuse state mutation logic
  ...commonMutations,
};

const usermanagementBackend = "usermanagement-backend";
const countriesServiceBackend = "countries-service-backend";
const actions = {
  [UPDATE_ACCOUNT_USER]({ commit }, payload) {
    // return the promise, so components can react on them
    return api
      .updateAccountUser(payload)
      .then((response) => {
        if (response.status === 200) {
          commit(UPDATE_ACCOUNT_USER_SUCCESS, response.data);
          const messages = {
            error: [],
            success: ["accountUpdateSuccess"],
          };
          commit(ADD_MESSAGE_OBJ, messages, { root: true });
        }
      })
      .catch((err) => {
        const data = processError(err);
        commit(API_ERROR, data);
        commitMessage(commit, data["messages"]);
        // rethrow err so that promise does not resolve
        throw err;
      });
  },
  [FETCH_ACCOUNT_USER]({ commit }) {
    return api
      .getAccountUser()
      .then((response) => {
        commit(FETCH_ACCOUNT_USER_SUCCESS, response.data);
      })
      .catch((err) => {
        commit(SET_DATA, {
          displaySite: false,
          errors: { statusCode: err.response.status },
        });
        const messages = { success: [], error: [] };
        if (err.response.status === 404) {
          messages.error.push("clientError.account");
        }
        commitMessage(commit, messages);
      });
  },
  [GET_PERMISSIONS_FROM_TOKEN]({ commit }) {
    const keycloak = this._vm.$keycloak;
    function getPermissionFromToken(permission, resourceName) {
      let resource = keycloak.tokenParsed.resource_access[resourceName];
      return resource === undefined
        ? false
        : resource.roles.indexOf(permission) !== -1;
    }
    function getRoleAccess(permission) {
      return keycloak.tokenParsed.realm_access.roles.indexOf(permission) !== -1;
    }
    commit(commonMutationTypes.SET_DATA, {
      isDeveloper: getRoleAccess(
        "DEVELOPER_AUTHORIZATION",
        usermanagementBackend
      ),
      isIntegrator: getRoleAccess(
        "INTEGRATOR_AUTHORIZATION",
        usermanagementBackend
      ),
      canViewEcosystemUsers: getPermissionFromToken(
        "VIEW_USERS",
        usermanagementBackend
      ),
      canEditEcosystemUsers: getPermissionFromToken(
        "EDIT_USERS",
        usermanagementBackend
      ),
      canDeleteEcosystemUsers: getPermissionFromToken(
        "DELETE_USERS",
        usermanagementBackend
      ),
      canInviteEcosystemUsers: getPermissionFromToken(
        "INVITE_USERS",
        usermanagementBackend
      ),
      canViewEcosystemCompanies: getPermissionFromToken(
        "VIEW_COMPANIES",
        usermanagementBackend
      ),
      canEditEcosystemCompanies: getPermissionFromToken(
        "EDIT_COMPANIES",
        usermanagementBackend
      ),
      canDeleteEcosystemCompanies: getPermissionFromToken(
        "DELETE_COMPANIES",
        usermanagementBackend
      ),
      canViewEcosystemCountries: getPermissionFromToken(
        "VIEW_COUNTRIES",
        countriesServiceBackend
      ),
      canManageCompanyDetails: getPermissionFromToken(
        "MANAGE_COMPANY_DETAILS",
        usermanagementBackend
      ),
      canManageCompanyUsers: getPermissionFromToken(
        "MANAGE_COMPANY_USERS",
        usermanagementBackend
      ),
      canInviteCompanyUsers: getPermissionFromToken(
        "INVITE_COMPANY_USERS",
        usermanagementBackend
      ),
      canManageCompanyRegistrations: getPermissionFromToken(
        "MANAGE_COMPANY_REGISTRATIONS",
        usermanagementBackend
      ),
      canBypassPjecoScreening: getPermissionFromToken(
        "BYPASS_PJECO_SCREENING",
        usermanagementBackend
      ),
      canTriggerTermsAndConditionsUpdate: getPermissionFromToken(
        "TRIGGER_TERMS_AND_CONDITIONS_UPDATE",
        usermanagementBackend
      ),
    });
  },
  [DELETE_USER]({ commit }) {
    const keycloak = this._vm.$keycloak;
    return api
      .deleteSelf()
      .then((response) => {
        if (response.status === 204) {
          const redirectUri =
            location.protocol +
            "//" +
            location.host.replace(/accounts\./, "sso.") +
            "/account-deleted?success=true";
          keycloak.logoutFn(redirectUri);
        }
      })
      .catch((err) => {
        const data = processError(err);
        let messages = { success: [], error: [] };
        if (
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          messages.error.push("clientError.deleteAccount");
        } else {
          messages = data["messages"];
        }
        commitMessage(commit, messages);
      });
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  modules: {
    // to not confuse with company module this is a submodule of account
    company,
  },
};
