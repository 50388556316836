<template>
  <div>
    <div v-for="entry in permissionsToIterate" :key="entry.header">
      <template v-if="entry.shouldShow()">
        <v-row class="mb-8">
          <v-col>
            <h3 class="mb-4">{{ $t(entry.header) }}</h3>
            <p
              v-if="entry.permissions.length === 0"
              class="permission-full-access"
            >
              {{ $t("userManagementHasFullAccess") }}
            </p>
            <template v-else>
              <v-row
                v-if="permissionGroup.shouldShow()"
                v-for="permissionGroup in entry.permissions"
                :key="permissionGroup.name"
              >
                <v-col>
                  <CDCheckbox
                    color="primary"
                    :name="permissionGroupCheckboxName(permissionGroup)"
                    :checked="isPermissionGroupActive(permissionGroup)"
                    :disabled="asyncNotDone"
                    @change="permissionGroupEdited(permissionGroup)"
                  >
                    <template v-slot:label>
                      <p>{{ $t(groupName(permissionGroup)) }}</p>
                      <small>{{ $t(groupExplanation(permissionGroup)) }}</small>
                    </template>
                  </CDCheckbox>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import { permissionNames } from "@/utils/enums";
import { VRow, VCol } from "vuetify/lib";

export default {
  name: "PermissionGroupsList",
  props: ["originalPermissions", "companyTypes", "asyncNotDone"],
  components: {
    VRow,
    VCol,
  },
  data() {
    return {
      // Copy permissions object so that comparing with the original is possible
      newPermissions: Object.assign({}, this.originalPermissions),
    };
  },
  computed: {
    permissionsToIterate() {
      return [
        {
          header: "section.ADMINISTRATION",
          permissions: [
            {
              name: permissionNames.COMPANY_ACCOUNT_MANAGER,
              shouldShow: () => true,
            },
          ],
          shouldShow: () => true,
        },
        {
          header: "section.BUY_AND_MANAGE",
          permissions: [
            {
              name: permissionNames.PAYMENT_MANAGER,
              shouldShow: () => this.companyTypes.includes("INTEGRATOR"),
            },
            {
              name: permissionNames.CAMERA_MANAGER,
              shouldShow: () => this.isDeveloperOrIntegrator(),
            },
            {
              name: permissionNames.PROJECT_MANAGER,
              shouldShow: () => this.companyTypes.includes("INTEGRATOR"),
            },
          ],
          shouldShow: () => this.isDeveloperOrIntegrator(),
        },
        {
          header: "section.DEVELOP_AND_SELL",
          permissions: [],
          shouldShow: () => this.companyTypes.includes("DEVELOPER"),
        },
      ];
    },
    permissionGroupsChanged() {
      return (
        JSON.stringify(this.newPermissions) !==
        JSON.stringify(this.originalPermissions)
      );
    },
  },
  methods: {
    permissionGroupEdited(group) {
      let groupName = group.name;
      this.newPermissions[groupName] = !this.newPermissions[groupName];
      this.$emit("permissionGroupsEdited", {
        value: this.permissionGroupsChanged,
        newPermissions: this.newPermissions,
      });
    },
    isDeveloperOrIntegrator() {
      return this.companyTypes.some((r) =>
        ["INTEGRATOR", "DEVELOPER"].includes(r)
      );
    },
    isPermissionGroupActive(group) {
      return this.newPermissions[group.name];
    },
    groupCapitalized(group) {
      const name = group.name;
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    groupName(group) {
      return "userManagement" + this.groupCapitalized(group);
    },
    groupExplanation(group) {
      return this.groupName(group) + "Explanation";
    },
    permissionGroupCheckboxName(group) {
      return "Checkbox" + this.groupCapitalized(group);
    },
  },
};
</script>

<style lang="scss" scoped>
small {
  color: var(--azena-grey-80);
  font-size: 14px;
}

::v-deep .v-input--selection-controls {
  margin: 0 !important;
}

::v-deep .v-input__slot {
  margin: 0 !important;
}

::v-deep .v-label {
  flex-direction: column;
  align-items: start !important;
}

::v-deep .v-input__slot {
  align-items: flex-start !important;
  column-gap: 8px;
}
</style>
