import api from "../api";
import processError from "../utils/processError";
import { buildAndCommitMessage } from "../utils/commit";
import {
  accountActionTypes,
  accountCompanyUsersActionTypes,
  userActionTypes,
} from "../actionTypes";

import {
  accountCompanyMutationTypes,
  accountMutationTypes,
  commonMutationTypes,
} from "../mutationTypes";
import { commonMutations } from "../commonMutations";
import { sortInvitations, sortUsers } from "../utils/sorting";

const state = () => ({
  ...resetState,
  permissions: {},
  doneLoading: {
    // saves the loading states of all server requests
    invitations: false,
    users: false,
  },
});

const resetInfo = {
  errors: {},
};

const resetState = {
  users: [],
  invitations: [],
  ...resetInfo,
};

const { GET_USERS_FOR_OWN_COMPANY } = accountActionTypes;
const {
  INVITE_NEW_USER,
  SEND_INVITATION_TO_BACKEND,
  GET_INVITATIONS_FOR_OWN_COMPANY,
  DELETE_USER_OR_INVITATION,
} = accountCompanyUsersActionTypes;
const { SET_USERS } = accountMutationTypes;
const { SET_DATA, ADD_MESSAGE_OBJ, API_ERROR } = commonMutationTypes;
const {
  UPDATE_INVITATION_SUCCESS,
  RESEND_INVITATION_SUCCESS,
  SEND_INVITATION_SUCCESS,
  SET_INVITATIONS,
  REMOVE_SINGLE_INVITATION,
  REMOVE_SINGLE_USER,
} = accountCompanyMutationTypes;
const { RESEND_INVITATION } = userActionTypes;

const mutations = {
  [SET_USERS](state, data) {
    state.users = sortUsers(data);
  },
  [SET_INVITATIONS](state, data) {
    state.invitations = sortInvitations(data);
  },
  [REMOVE_SINGLE_INVITATION](state, data) {
    const index = state.invitations.indexOf(data);
    state.invitations.splice(index, 1);
  },
  [REMOVE_SINGLE_USER](state, data) {
    const index = state.users.indexOf(data);
    state.users.splice(index, 1);
  },
  [SEND_INVITATION_SUCCESS](state) {
    Object.assign(state, resetInfo);
    state.errors = {};
  },
  [RESEND_INVITATION_SUCCESS](state) {
    Object.assign(state, resetInfo);
    state.errors = {};
  },
  [UPDATE_INVITATION_SUCCESS](state) {
    Object.assign(state, resetInfo);
    state.errors = {};
  },
  ...commonMutations,
};

const actions = {
  [GET_USERS_FOR_OWN_COMPANY]({ commit }) {
    commit(SET_DATA, { doneLoading: { users: false } });
    return api
      .getUsersForOwnCompany()
      .then((response) => {
        commit(SET_USERS, response.data);
        commit(SET_DATA, { doneLoading: { users: true } });
      })
      .catch((err) => {
        const data = processError(err, "serverError.userList");
        buildAndCommitMessage(commit, data);
        commit(SET_DATA, { errors: { userListError: true } });
        commit(SET_DATA, { doneLoading: { users: true } });
      });
  },
  [GET_INVITATIONS_FOR_OWN_COMPANY]({ commit }) {
    commit(SET_DATA, { doneLoading: { invitations: false } });
    return api
      .getUserInvitationsForOwnCompany()
      .then((response) => {
        commit(SET_INVITATIONS, response.data);
        commit(SET_DATA, { doneLoading: { invitations: true } });
      })
      .catch((err) => {
        const data = processError(err, "serverError.invitationList");
        buildAndCommitMessage(commit, data);
        commit(SET_DATA, { errors: { invitationListError: true } });
        commit(SET_DATA, { doneLoading: { invitations: true } });
      });
  },
  [INVITE_NEW_USER]({ commit, state, dispatch }, payload) {
    const inviteeEmail = payload.inviteeEmail;
    // Check if invitee is already in company
    if (state.users.some((user) => inviteeEmail === user.email)) {
      return Promise.resolve().then(() => {
        commit(
          ADD_MESSAGE_OBJ,
          {
            error: ["userManagementInvitationFailAlreadyInCompany"],
            success: [],
          },
          { root: true }
        );
      });
    }
    return dispatch(SEND_INVITATION_TO_BACKEND, {
      inviteeEmail: inviteeEmail,
      inviteePermissions: payload.inviteePermissions,
    });
  },
  [SEND_INVITATION_TO_BACKEND]({ commit, dispatch }, payload) {
    return api
      .sendInvitationToJoinCompany({
        email: payload.inviteeEmail,
        permissions: payload.inviteePermissions,
      })
      .then((response) => {
        if (response.status === 200) {
          commit(UPDATE_INVITATION_SUCCESS);
          const messages = {
            error: [],
            success: ["userManagementInvitationPermissionUpdateSuccess"],
          };
          commit(ADD_MESSAGE_OBJ, messages, { root: true });
        } else if (response.status === 201) {
          commit(SEND_INVITATION_SUCCESS);
          const messages = {
            error: [],
            success: ["userManagementInvitationSentSuccess"],
          };
          commit(ADD_MESSAGE_OBJ, messages, { root: true });
        }
        dispatch(GET_INVITATIONS_FOR_OWN_COMPANY);
      })
      .catch((err) => {
        const data = processError(err);
        data["messages"].error.push("userManagementInvitationSentFail");
        buildAndCommitMessage(commit, data);
        commit(API_ERROR, data);
      });
  },
  [RESEND_INVITATION]({ commit, dispatch }, payload) {
    return api
      .resendInvitation(payload.userName)
      .then((response) => {
        dispatch(GET_INVITATIONS_FOR_OWN_COMPANY);
        if (response.status === 200) {
          commit(RESEND_INVITATION_SUCCESS);
          const messages = {
            error: [],
            success: ["userManagementInvitationResentSuccess"],
          };
          commit(ADD_MESSAGE_OBJ, messages, { root: true });
        } else {
          return Promise.reject("unexpected response");
        }
      })
      .catch((err) => {
        dispatch(GET_INVITATIONS_FOR_OWN_COMPANY);
        const data = processError(err);
        data["messages"].error.push("userManagementInvitationResentFail");
        buildAndCommitMessage(commit, data);
        commit(API_ERROR, data);
      });
  },
  [DELETE_USER_OR_INVITATION]({ commit }, { payload, isInvitation }) {
    return api
      .deleteUserInOwnCompany(payload.userName)
      .then((response) => {
        if (response.status === 204) {
          commit(
            ADD_MESSAGE_OBJ,
            {
              error: [],
              success: [
                {
                  key: isInvitation
                    ? "userManagementInvitationDeleted"
                    : "userManagementUserDeleted",
                  params: payload,
                },
              ],
            },
            { root: true }
          );
        }
        const mutationToUse = isInvitation
          ? REMOVE_SINGLE_INVITATION
          : REMOVE_SINGLE_USER;
        commit(mutationToUse, payload);
      })
      .catch((err) => {
        const data = processError(err);
        const errorToPush = isInvitation
          ? "userManagementInvitationDeletionFail"
          : "userManagementUserDeletionFail";
        data["messages"].error.push(errorToPush);
        buildAndCommitMessage(commit, data);
        commit(API_ERROR, data);
      });
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
