import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../lang/en.json";

Vue.use(VueI18n);

const fallbackLanguage = "en";
const supportedLanguages = ["en"];

// Adding ISO country codes
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
Object.assign(en, countries.getNames(setLanguage()));

export const i18n = new VueI18n({
  locale: setLanguage(),
  fallbackLocale: fallbackLanguage,
  messages: { en },
});

// Get the language from the browser
function setLanguage() {
  let browserLanguage = navigator.language.slice(0, 2);
  return supportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : fallbackLanguage;
}
