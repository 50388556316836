<template>
  <div class="v-application modal">
    <v-card flat>
      <v-card-title>
        <h2>{{ $t("userPermissions") }}</h2>
      </v-card-title>
      <v-card-subtitle>
        <p>
          {{
            $t("userManagementPermissionGroupsModalDescription") +
            fullnameOrEmail
          }}
        </p>
      </v-card-subtitle>
      <v-card-text>
        <form>
          <PermissionGroupsList
            v-bind:original-permissions="user.permissions"
            v-bind:company-types="companyTypes"
            v-on:permissionGroupsEdited="handlePermissionGroupsEdited"
          ></PermissionGroupsList>
        </form>
      </v-card-text>
      <v-card-actions class="actions">
        <CDButton name="closeButton" large @click="$emit('close')">
          {{ $t("cancel") }}
        </CDButton>
        <CDButton
          name="saveButton"
          color="primary"
          large
          :disabled="!permissionGroupsChanged"
          @click="savePermissionGroupsClicked()"
          >{{ $t("save") }}
        </CDButton>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PermissionGroupsList from "../Components/PermissionGroupsList";
import {
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VCardActions,
} from "vuetify/lib";

export default {
  name: "PermissionGroupsModal",
  components: {
    PermissionGroupsList,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardText,
    VCardActions,
  },
  props: ["user", "companyTypes", "savePermissionGroupsFunction"],
  data() {
    return {
      permissionGroupsChanged: false,
      newPermissions: {},
    };
  },
  computed: {
    fullnameOrEmail() {
      return this.user.firstName
        ? `${this.user.firstName} ${this.user.lastName}`
        : this.user.email;
    },
  },
  methods: {
    handlePermissionGroupsEdited(event) {
      this.permissionGroupsChanged = event.value;
      this.newPermissions = event.newPermissions;
    },
    savePermissionGroupsClicked() {
      this.savePermissionGroupsFunction(this.newPermissions).then(() => {
        this.$emit("close");
      });
    },
  },
};
</script>
