const userActionTypes = {
  DELETE_USER_OR_INVITATION: "DELETE_USER_OR_INVITATION",
  UPDATE_USER_PERMISSION_GROUPS_ECOSYSTEM:
    "UPDATE_USER_PERMISSION_GROUPS_ECOSYSTEM",
  UPDATE_USER_PERMISSION_GROUPS_COMPANY:
    "UPDATE_USER_PERMISSION_GROUPS_COMPANY",
  RESEND_INVITATION: "RESEND_INVITATION",
};

const commonActionTypes = {
  POP_FIRST_MESSAGE_OBJ: "REMOVE_FIRST_MESSAGE_OBJ",
  IS_LOADING: "IS_LOADING",
  LOAD_COUNTRIES: "LOAD_COUNTRIES",
};

const accountActionTypes = {
  UPDATE_ACCOUNT_USER: "UPDATE_ACCOUNT_USER",
  FETCH_ACCOUNT_USER: "FETCH_ACCOUNT_USER",
  FETCH_ACCOUNT_COMPANY: "FETCH_ACCOUNT_COMPANY",
  GET_PERMISSIONS_FROM_TOKEN: "GET_PERMISSIONS_FROM_TOKEN",
  GET_USERS_FOR_OWN_COMPANY: "GET_USERS_FOR_OWN_COMPANY",
  DELETE_USER: "DELETE_USER",
};

const accountCompanyUsersActionTypes = {
  INVITE_NEW_USER: "INVITE_NEW_USER",
  SEND_INVITATION_TO_BACKEND: "SEND_INVITATION_TO_BACKEND",
  GET_INVITATIONS_FOR_OWN_COMPANY: "GET_INVITATIONS_FOR_OWN_COMPANY",
  DELETE_USER_OR_INVITATION: "DELETE_USER_OR_INVITATION",
};

const companyActionTypes = {
  CREATE_COMPANY: "CREATE_COMPANY",
};

const ecosystemActionTypes = {
  INVITE_NEW_USER: "INVITE_NEW_USER",
  SEND_INVITATION_TO_BACKEND: "SEND_INVITATION_TO_BACKEND",
  SELECT_COMPANY: "SELECT_COMPANY",
  GET_USERS_FOR_COMPANY: "GET_USERS_FOR_COMPANY",
  GET_INVITATIONS_FOR_COMPANY: "GET_INVITATIONS_FOR_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",
  UPDATE_COMPANY_FRIENDLY_NAME: "UPDATE_COMPANY_FRIENDLY_NAME",
  UPDATE_COMPANY_PRE_APPROVED_APPS: "UPDATE_COMPANY_PRE_APPROVED_APPS",
  COPY_COMPANY_ID: "COPY_COMPANY_ID",
  UPDATE_COMPANY_PERMISSIONS: "UPDATE_COMPANY_PERMISSIONS",
  UPDATE_APP_PURCHASE_EMAIL: "UPDATE_APP_PURCHASE_EMAIL",
  UPDATE_APP_SALES_EMAIL: "UPDATE_APP_SALES_EMAIL",
  ADD_SELLER_EMAIL: "ADD_SELLER_EMAIL",
};

export {
  accountActionTypes,
  companyActionTypes,
  commonActionTypes,
  ecosystemActionTypes,
  userActionTypes,
  accountCompanyUsersActionTypes,
};
