import Vue from "vue";
import Vuetify from "vuetify/lib";

import {
  options,
  CDNavbar,
  CDNavbarLink,
  CDHomeSwitcher,
  CDSeparator,
  CDButton,
  CDButtonText,
  CDButtonTextIcon,
  CDInput,
  CDToggle,
  CDNotificationToastWrapper,
  CDNotificationToast,
  CDFlyoutMenu,
  CDFlyoutMenuItem,
  CDCheckbox,
  CDButtonIcon,
  CDFooter,
  CDSidebar,
  CDSidebarItem,
  CDCookieBanner,
  CDNeedHelpButton,
} from "cd-system";
import "cd-system/tokens/fonts/fonts.css";
import "material-design-icons/iconfont/material-icons.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.component("CDNavbar", CDNavbar);
Vue.component("CDNavbarLink", CDNavbarLink);
Vue.component("CDHomeSwitcher", CDHomeSwitcher);
Vue.component("CDSeparator", CDSeparator);
Vue.component("CDButton", CDButton);
Vue.component("CDButtonTextIcon", CDButtonTextIcon);
Vue.component("CDButtonText", CDButtonText);
Vue.component("CDButtonIcon", CDButtonIcon);
Vue.component("CDInput", CDInput);
Vue.component("CDToggle", CDToggle);
Vue.component("CDNotificationToastWrapper", CDNotificationToastWrapper);
Vue.component("CDNotificationToast", CDNotificationToast);
Vue.component("CDFlyoutMenu", CDFlyoutMenu);
Vue.component("CDFlyoutMenuItem", CDFlyoutMenuItem);
Vue.component("CDCheckbox", CDCheckbox);
Vue.component("CDSidebar", CDSidebar);
Vue.component("CDSidebarItem", CDSidebarItem);
Vue.component("CDFooter", CDFooter);
Vue.component("CDCookieBanner", CDCookieBanner);
Vue.component("CDNeedHelpButton", CDNeedHelpButton);

Vue.use(Vuetify);

export default new Vuetify({
  ...options,
});
