import rewritePattern from "regexpu-core";

const translatedMessages = {
  methods: {
    getMessageType(message) {
      if (message["success"] && message["success"].length > 0) {
        return "success";
      } else if (message["error"] && message["error"].length > 0) {
        return "error";
      } else {
        throw new Error("Unknown message type");
      }
    },
    getMessageText(message) {
      const type = this.getMessageType(message);

      if (type === "success") {
        if (type === "success" && message.success[0] instanceof Object) {
          return this.$t(message.success[0].key, message.success[0].params);
        } else {
          return this.$t(message.success[0]);
        }
      } else if (type === "error") {
        return this.$t(message.error[0]);
      }
    },
    translatedMessages(messages) {
      return messages.map((message) => {
        return {
          index: message.index,
          type: this.getMessageType(message),
          text: this.getMessageText(message),
        };
      });
    },
  },
};

const errorForField = {
  methods: {
    fieldHasError(fieldName) {
      return this.errors[fieldName];
    },
    translatedErrorForField(fieldName) {
      if (this.fieldHasError(fieldName) && this.errors[fieldName].reason) {
        const langKey = `${this.errors[fieldName].reason}`;
        return this.$te(langKey)
          ? this.$t(langKey)
          : this.$te("validation." + langKey)
          ? this.$t("validation." + langKey)
          : this.$t("validation.invalidInput");
      } else {
        return "";
      }
    },
  },
};

const dateMixin = {
  methods: {
    dateFormat(dateInput) {
      const date = new Date(dateInput);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString(undefined, options);
    },
  },
};

const companyTypes = {
  methods: {
    formatCompanyTypes(types) {
      return types
        .map((e) => {
          const type = e.toLowerCase();
          return type.charAt(0).toUpperCase() + type.slice(1);
        })
        .join(", ");
    },
  },
};

const validateMixin = {
  methods: {
    validateValueAgainstPattern(value, pattern) {
      const regex = RegExp(
        rewritePattern(pattern, "u", {
          unicodePropertyEscape: true,
        })
      );
      return regex.test(value);
    },
  },
};

export {
  errorForField,
  translatedMessages,
  dateMixin,
  companyTypes,
  validateMixin,
};
