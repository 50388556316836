<template>
  <v-form ref="myProfileForm">
    <v-container>
      <v-row v-if="errors && errors.statusCode">
        <v-col>
          <Error :status-code="errors.statusCode" />
        </v-col>
      </v-row>
      <template v-if="displaySite">
        <v-row class="mb-16">
          <v-col>
            <h1>{{ fullName }}</h1>
            <p>{{ $t("myProfileSubtitle") }}</p>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <h2>{{ $t("userPersonalDetails") }}</h2>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <CDInput
              name="firstNameInput"
              v-model="firstName"
              :label="$t('userFirstname')"
              :rules="validation.firstName"
              :error-messages="translatedErrorForField('firstName')"
              @change="mapInputToState('firstName', $event)"
            />
          </v-col>
          <v-col>
            <CDInput
              name="lastNameInput"
              v-model="lastName"
              :label="$t('userLastname')"
              :rules="validation.lastName"
              :error-messages="translatedErrorForField('lastName')"
              @change="mapInputToState('lastName', $event)"
            />
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <h2>{{ $t("userContactDetails") }}</h2>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <CDInput
              name="phoneInput"
              v-model="phoneNumber"
              :label="$t('userPhone')"
              :error-messages="translatedErrorForField('phoneNumber')"
              @change="mapInputToState('phoneNumber', $event)"
            />
          </v-col>
          <v-col>
            <CDInput
              name="emailInput"
              v-model="email"
              :label="$t('email')"
              disabled
            />
          </v-col>
        </v-row>
        <v-row class="mb-16">
          <v-col>
            <CDButton
              name="saveButton"
              color="primary"
              large
              :loading="isLoading"
              @click="submitFormClickEvent()"
              >{{ $t("save") }}
            </CDButton>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>{{ $t("changePasswordHeader") }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ $t("changePasswordInfo") }}</p>
          </v-col>
        </v-row>
        <v-row class="mb-16">
          <v-col>
            <CDButton name="changePassword" @click="changePassword()">
              {{ $t("changePasswordButton") }}
            </CDButton>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>{{ $t("deleteAccount") }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ $t("deleteAccountInfo") }}</p>
          </v-col>
        </v-row>
        <v-row class="mb-16">
          <v-col>
            <CDButton
              name="deleteAccount"
              @click="openConfirmationModalForDeletion()"
            >
              {{ $t("deleteAccount") }}
            </CDButton>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-form>
</template>

<script>
import Error from "./Error";
import ConfirmationModal from "./Modals/ConfirmationModal";
import { mapActions, mapState } from "vuex";
import { accountActionTypes } from "@/store/actionTypes";

import {
  errorForField,
  translatedMessages,
  validateMixin,
} from "@/utils/mixins";

import { ACCOUNT_NAMESPACE } from "@/store/namespaces";
import { VForm, VContainer, VRow, VCol } from "vuetify/lib";

export default {
  name: "MyProfile",
  components: { Error, VForm, VContainer, VRow, VCol },
  mixins: [errorForField, translatedMessages, validateMixin],
  data() {
    return {
      ...resetState(this),
      validation: {
        firstName: [
          (value) => {
            const pattern =
              "^[\\p{Script_Extensions=Latin}]{1}[\\p{L}\\p{Zs}\\p{Pd}\\p{Pc}()'&$.:,+/*]*$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("validation.INVALID_FIRST_NAME")
            );
          },
        ],
        lastName: [
          (value) => {
            const pattern =
              "^[\\p{Script_Extensions=Latin}]{1}[\\p{L}\\p{Zs}\\p{Pd}\\p{Pc}()'&$.:,+/*]*$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("validation.INVALID_LAST_NAME")
            );
          },
        ],
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState(ACCOUNT_NAMESPACE, {
      errors: (state) => state.errors,
      savedFirstname: (state) => state.firstName,
      savedLastname: (state) => state.lastName,
      savedPhoneNumber: (state) => state.phoneNumber,
      email: (state) => state.email,
      displaySite: (state) => state.displaySite,
      fullName: (state) => state.fullName,
    }),
  },
  methods: {
    ...mapActions({
      deleteAccount: ACCOUNT_NAMESPACE + "/" + accountActionTypes.DELETE_USER,
    }),
    changePassword() {
      const passwordUrl = this.$keycloak
        .createAccountUrl()
        .replace("sast/account", "sast/account/password");
      window.open(passwordUrl, "_blank");
    },
    openConfirmationModalForDeletion() {
      this.$modal.show(
        ConfirmationModal,
        {
          title: "deleteAccountModalHeader",
          translatedTextParagraphs: [this.$t("deleteAccountModalInfo")],
          confirmClickedFunction: () => this.deleteAccount(),
        },
        {
          height: "auto",
          width: "1200px",
          clickToClose: false,
        }
      );
    },
    submitFormClickEvent() {
      if (!this.$refs.myProfileForm.validate()) return;

      this.isLoading = true;

      this.$store
        .dispatch(
          ACCOUNT_NAMESPACE + "/" + accountActionTypes.UPDATE_ACCOUNT_USER,
          {
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
          }
        )
        .then(() => {
          // updating data on component reactively
          Object.assign(this.$data, resetState(this));
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    mapInputToState(field, value) {
      this[field] = value;
    },
  },
  created() {
    this.$store
      .dispatch("account/" + accountActionTypes.FETCH_ACCOUNT_USER)
      .then(() => {
        Object.assign(this.$data, resetState(this));
      });
  },
};

function resetState(component) {
  return {
    firstName: component.savedFirstname,
    lastName: component.savedLastname,
    phoneNumber: component.savedPhoneNumber,
  };
}
</script>
