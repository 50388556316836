import api from "../api";
import { commitMessage } from "../utils/commit";
import { accountActionTypes } from "../actionTypes";
import { commonMutationTypes } from "../mutationTypes";
import { commonMutations } from "../commonMutations";
import users from "./accountCompanyUsers";

const { FETCH_ACCOUNT_COMPANY } = accountActionTypes;
const { SET_DATA } = commonMutationTypes;

const state = () => ({
  ...resetState,
});

const resetState = {
  companyInformation: null,
  errorStatusCode: null,
  displaySite: true,
};

const mutations = {
  ...commonMutations,
};

const actions = {
  [FETCH_ACCOUNT_COMPANY]({ commit }) {
    return api
      .getAccountCompany()
      .then((response) => {
        commit(SET_DATA, {
          companyInformation: response.data,
          errorStatusCode: null,
        });
      })
      .catch((err) => {
        commit(SET_DATA, {
          displaySite: false,
          companyInformation: null,
          errorStatusCode: err.response.status,
        });
        const messages = { success: [], error: [] };
        if (err.status === 404) {
          messages.error.push("clientError.company");
        }
        commitMessage(commit, messages);
      });
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  modules: {
    users,
  },
};
