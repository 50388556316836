import Vue from "vue";
import App from "./App.vue";
import Error from "./views/Error.vue";
import router from "./router";
import VueKeycloak from "./plugins/VueKeycloak.js";
import axios from "axios";
import { i18n } from "./plugins/i18n";
import store from "./store";

// import the modal package without css, since we will define custom css
import VModal from "vue-js-modal/dist/ssr.nocss";

import vuetify from "./plugins/vuetify";

// Global Vue configuration used for the success and error cases
Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  /^io-/, // ignore all web components starting with "io-"
];

axios
  .get("/rest/config/environment")
  .then((response) => {
    const config = {
      authUrl: response.data.keycloakUrl,
      authRealm: "sast",
      authClientId: "usermanagement-frontend",
    };
    Vue.use(VueKeycloak, {
      config: config,
      init: { onLoad: "login-required" },
      onReady: () => {
        /* eslint-disable no-new */
        window.vueApp = new Vue({
          store,
          router,
          i18n,
          vuetify,
          render: (h) => h(App),
        }).$mount("#app");
      },
    });
    Vue.use(VModal, {
      dynamic: true,
      injectModalsContainer: true,
    });

    let requestList = 0;

    const req = {
      pending: () => {
        requestList++;
        store.commit("IS_LOADING", true);
      },
      done: () => {
        requestList--;
        if (requestList <= 0) {
          store.commit("IS_LOADING", false);
        }
      },
    };

    // axios interceptor for adding authorization header
    axios.interceptors.request.use(
      (config) => {
        req.pending();
        const token = store._vm.$keycloak.token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (err) => {
        req.done();
        return Promise.reject(err);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        req.done();
        return response;
      },
      (err) => {
        req.done();
        return Promise.reject(err);
      }
    );
  })
  .catch((error) => {
    if (error.response) {
      new Vue({
        i18n,
        render: (h) =>
          h(Error, {
            props: { statusCode: 500 },
          }),
      }).$mount("#err");
    } else {
      alert(`Unknown error: ${error.message}`);
    }
  });
