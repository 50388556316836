<template>
  <div class="v-application modal">
    <v-card flat>
      <v-card-title>
        <h2>{{ $t("userManagementInvitationModalHeader") }}</h2>
      </v-card-title>
      <v-card-subtitle>
        <p>{{ $t("userManagementInvitationModalDescription") }}</p>
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="invitationForm">
          <CDInput
            name="inviteeEmailInput"
            v-model="inviteeEmail"
            :label="$t('email')"
            :rules="validation.email"
          />
          <h2 class="mb-4">{{ $t("userPermissions") }}</h2>
          <p class="mb-8">
            {{ $t("userManagementInvitationModalPermissionsListDescription") }}
          </p>
          <PermissionGroupsList
            :original-permissions="permissions"
            :company-types="companyTypes"
            @permissionGroupsEdited="handlePermissionGroupsEdited"
          ></PermissionGroupsList>
        </v-form>
      </v-card-text>
      <v-card-actions class="actions">
        <CDButton name="closeButton" large @click="$emit('close')">
          {{ $t("cancel") }}
        </CDButton>
        <CDButton
          name="sendButton"
          color="primary"
          large
          :loading="isLoading"
          @click="sendInvitationClicked"
          >{{ $t("send") }}
        </CDButton>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  accountCompanyUsersActionTypes,
  ecosystemActionTypes,
} from "@/store/actionTypes";
import PermissionGroupsList from "../Components/PermissionGroupsList";
import { mapState } from "vuex";
import {
  ECOSYSTEM_NAMESPACE,
  ACCOUNT_COMPANY_USERS_NAMESPACE,
} from "@/store/namespaces";
import {
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VCardActions,
  VForm,
} from "vuetify/lib";

export default {
  name: "invitation-modal",
  components: {
    PermissionGroupsList,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardText,
    VCardActions,
    VForm,
  },
  data() {
    return {
      inviteeEmail: "",
      inviteePermissions: {},
      validation: {
        email: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) || this.$t("validation.EMAIL_NOT_WELL_FORMED")
            );
          },
        ],
      },
      isLoading: false,
    };
  },
  props: ["companyId", "companyTypes"],
  computed: {
    ...mapState(ACCOUNT_COMPANY_USERS_NAMESPACE, ["permissions"]),
  },
  methods: {
    handlePermissionGroupsEdited(event) {
      this.inviteePermissions = event.newPermissions;
    },
    sendInvitationClicked() {
      if (!this.$refs.invitationForm.validate()) return;

      this.isLoading = true;

      const dispatchAction = this.companyId
        ? ECOSYSTEM_NAMESPACE + "/" + ecosystemActionTypes.INVITE_NEW_USER
        : ACCOUNT_COMPANY_USERS_NAMESPACE +
          "/" +
          accountCompanyUsersActionTypes.INVITE_NEW_USER;
      this.$store
        .dispatch(dispatchAction, {
          inviteeEmail: this.inviteeEmail,
          inviteePermissions: this.inviteePermissions,
          inviteeCompanyId: this.companyId,
        })
        .then(() => {
          this.$emit("close");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    keySubmit(field, event) {
      event.preventDefault();
      this.inviteeEmail = event.target.value;
      this.sendInvitationClicked();
    },
  },
};
</script>

<style lang="scss" scoped>
[name="inviteeEmailInput"] {
  ::v-deep .v-label--active {
    left: -5px !important;
    top: 14px !important;
    background-color: #ffffff;
    padding: 0 5px 0 0;
  }
}
</style>
