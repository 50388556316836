const ACCOUNT_COMPANY_USERS_NAMESPACE = "account/company/users";
const ACCOUNT_COMPANY_NAMESPACE = "account/company";
const ACCOUNT_NAMESPACE = "account";
const EDIT_USER_AND_INVITATION_NAMESPACE = "editUser";
const ECOSYSTEM_NAMESPACE = "ecosystem";

export {
  ACCOUNT_COMPANY_USERS_NAMESPACE,
  ACCOUNT_COMPANY_NAMESPACE,
  ACCOUNT_NAMESPACE,
  EDIT_USER_AND_INVITATION_NAMESPACE,
  ECOSYSTEM_NAMESPACE,
};
