import { commonMutations } from "../commonMutations";
import api from "../api";
import processError from "../utils/processError";
import {
  accountActionTypes,
  accountCompanyUsersActionTypes,
  userActionTypes,
} from "../actionTypes";
import { commonMutationTypes, userMutationTypes } from "../mutationTypes";
import { ACCOUNT_COMPANY_USERS_NAMESPACE } from "../namespaces";

const { UPDATE_USER_PERMISSION_GROUPS_COMPANY } = userActionTypes;

const { GET_USERS_FOR_OWN_COMPANY } = accountActionTypes;

const { GET_INVITATIONS_FOR_OWN_COMPANY } = accountCompanyUsersActionTypes;
const { UPDATE_PERMISSIONS_SUCCESS } = userMutationTypes;
const { API_ERROR, ADD_MESSAGE_OBJ } = commonMutationTypes;

/**
 * Helper store module to cache data of a user or invitation that is currently edited
 */
const state = () => ({
  ...resetState,
});

const resetState = {
  errors: {},
};

const mutations = {
  [UPDATE_PERMISSIONS_SUCCESS](state) {
    Object.assign(state, resetState);
    state.errors = {};
  },
  ...commonMutations,
};

const actions = {
  [UPDATE_USER_PERMISSION_GROUPS_COMPANY](
    { commit, dispatch },
    { user, permissions, isInvitation }
  ) {
    let actionAfter;
    if (isInvitation) {
      actionAfter =
        ACCOUNT_COMPANY_USERS_NAMESPACE + "/" + GET_INVITATIONS_FOR_OWN_COMPANY;
    } else {
      actionAfter =
        ACCOUNT_COMPANY_USERS_NAMESPACE + "/" + GET_USERS_FOR_OWN_COMPANY;
    }
    const promiseToReturn = updateUserPermissions(
      api.updateUserPermissionGroupsCompany,
      this._vm.$keycloak.token,
      commit,
      user.userName,
      user.companyId,
      permissions,
      isInvitation
    );
    promiseToReturn.then(() => dispatch(actionAfter, null, { root: true }));
    return promiseToReturn;
  },
};

function updateUserPermissions(
  apiFunction,
  token,
  commit,
  userName,
  companyId,
  permissions,
  isInvitation
) {
  return apiFunction(userName, companyId, permissions, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      if (response.status === 204) {
        commit(UPDATE_PERMISSIONS_SUCCESS);
        const messages = { error: [] };
        if (isInvitation) {
          messages.success = [
            "userManagementInvitationPermissionUpdateSuccess",
          ];
        } else {
          messages.success = ["ecosystemUserPermissionUpdate"];
        }
        commit(ADD_MESSAGE_OBJ, messages, { root: true });
      }
    })
    .catch((err) => {
      const data = processError(err);
      commit(API_ERROR, data);
      commit(ADD_MESSAGE_OBJ, data["messages"], { root: true });
    });
}

export default {
  // use namespacing
  namespaced: true,
  mutations,
  actions,
  state,
};
