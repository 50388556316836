const possibleViolations = [
  "NOT_ACCOUNT_MANAGER",
  "ACTIVE_EMAIL_EXISTS",
  "COMPANY_ID_NOT_A_UUID",
  "COMPANY_ID_EMPTY",
  "MUST_NOT_BE_EMPTY",
  "VALUE_EMPTY",
  "VALUE_NULL",
  "EMAIL_NOT_WELL_FORMED",
  "COUNTRY_CODE_LENGTH_WRONG",
  "PHONE_NUMBER_NOT_WELL_FORMED",
  "GENERAL_INPUT_ERROR",
  "COMPANY_TYPE_EMPTY",
  "COMPANY_NAME_RESERVED",
  "COMPANY_DELETION_PROHIBITED",
];

const possibleServerErrorReasons = [
  "COMPANY_ATTRIBUTES_MISCONFIGURED",
  "USER_ATTRIBUTES_MISCONFIGURED",
];

const possibleAuthorizingErrorTypes = ["SAST_RESTRICTED"];

/**
 * @param response: response from server
 */
const handleServerError = (response) => {
  const errorMessages = [];
  const payload = response.data;
  let message;
  if (payload && payload.reason) {
    // custom response with reason given
    if (possibleServerErrorReasons.includes(payload.reason)) {
      message = "serverError." + payload.reason;
    } else {
      message = "serverError.UNKNOWN";
    }
  } else {
    message = "serverError.noResponse";
  }
  errorMessages.push(message);
  return errorMessages;
};

const handleAuthorizingError = (response) => {
  const errorMessages = [];
  const payload = response.data;
  let message;
  if (payload && payload.message) {
    if (possibleAuthorizingErrorTypes.includes(payload.message)) {
      message = "authorizingError." + payload.message;
    } else {
      message = "clientError.401";
    }
  } else {
    message = "authorizingError.noResponse";
  }
  errorMessages.push(message);
  return errorMessages;
};

/**
 * @param err: response from server
 * @param context String; further information on client context
 */
export default (err, context) => {
  const messages = { success: [], error: [] };
  const errors = {};
  const statusCode = err.response ? err.response.status : null;
  if (statusCode === 404) {
    messages.error.push("clientError.404");
  } else if (err.response && statusCode >= 400 && statusCode < 500) {
    if ([401].includes(statusCode)) {
      messages.error.push("clientError.401");
      // early return because data field in response is empty and does not need to be evaluated
      return {
        errors,
        messages,
      };
    } else if ([403].includes(statusCode)) {
      messages.error = [
        ...messages.error,
        ...handleAuthorizingError(err.response),
      ];
    }
    if (err.response.data.inputViolations) {
      for (let violation of err.response.data.inputViolations) {
        const error = violation;
        if (possibleViolations.includes(violation.reason)) {
          error.reason = "validation." + violation.reason;
        }
        errors[violation.field] = error;
      }
    }
  } else if (err.request) {
    if (context) {
      messages.error.push(context);
    }
    messages.error = [...messages.error, ...handleServerError(err.response)];
  } else {
    messages.error.push(err.message);
  }
  return {
    errors,
    messages,
  };
};
