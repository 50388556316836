<template>
  <div>
    <h2>
      {{ $t("userManagementInvitationListTitle") }}
    </h2>
    <v-data-table
      :disable-pagination="true"
      :disable-sort="true"
      :hide-default-footer="true"
      :loading="isLoading"
      :loading-text="$t('loading')"
      :no-data-text="$t('userManagementInvitationListEmptyMessage')"
      :headers="headers"
      :items="invitations"
    >
      <template v-slot:item.email="{ item }">
        {{ email(item) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ $t("userManagementInvitationStatus." + item.status) }}
      </template>
      <template v-slot:item.expirationDate="{ item }">
        {{ invitationExpirationDate(item) }}
      </template>
      <template v-slot:item.permissions="{ item }">
        <UserPermissionGroups v-bind:permissions="item.permissions" />
      </template>
      <template v-slot:item.actions="{ item }">
        <span :data-id="email(item)">
          <CDFlyoutMenu
            v-if="canEdit && hasOrderDeskPermission"
            :left="true"
            menu-icon="$more"
            color="primary"
          >
            <CDFlyoutMenuItem
              name="editPermissionGroups"
              icon="$edit"
              :title="$t('userManagementEditPermissionGroups')"
              @cdClick="editPermissionGroupsClicked(item)"
            />
            <CDFlyoutMenuItem
              name="resendInvitation"
              icon="$circlearrow"
              :title="$t('userManagementInvitationResend')"
              @cdClick="resendInvitationClicked(item)"
            />
            <CDFlyoutMenuItem
              name="deleteInvitation"
              icon="$trash"
              :title="$t('userManagementDeleteInvitation')"
              @cdClick="deleteInvitationClicked(item)"
            />
          </CDFlyoutMenu>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { orderDeskContact, permissionNames } from "@/utils/enums";
import { dateMixin } from "@/utils/mixins";
import UserPermissionGroups from "@/views/Components/UserPermissionGroups";
import { VDataTable } from "vuetify/lib";

export default {
  name: "UserInvitations",
  props: ["isLoading", "canEdit", "invitations"],
  components: {
    UserPermissionGroups,
    VDataTable,
  },
  mixins: [dateMixin],
  data() {
    return {
      headers: [
        {
          text: this.$t("userManagementUsersEmail"),
          value: "email",
          cellClass: "email",
        },
        {
          text: this.$t("userManagementInvitationStatusTitle"),
          value: "status",
          cellClass: "status",
        },
        {
          text: this.$t("userManagementInvitationExpirationDateTitle"),
          value: "expirationDate",
          cellClass: "expiration-date text-no-wrap",
        },
        {
          text: this.$t("userManagementUsersGroups"),
          value: "permissions",
          cellClass: "permissions",
        },
        {
          text: "",
          value: "actions",
          align: "end",
          cellClass: "text-right",
        },
      ],
    };
  },
  computed: {
    hasInvitations() {
      return this.invitations.length > 0;
    },
  },
  methods: {
    hasOrderDeskPermission(invitation) {
      return invitation.permissions[permissionNames.ORDER_DESK_MANAGER];
    },
    email(invitation) {
      return this.hasOrderDeskPermission(invitation)
        ? orderDeskContact.EMAIL
        : invitation.email;
    },
    invitationExpirationDate(invitation) {
      return this.dateFormat(invitation.expirationDate);
    },
    editPermissionGroupsClicked(invitation) {
      this.$emit("editPermissionGroupsClicked", invitation);
    },
    resendInvitationClicked(invitation) {
      this.$emit("resendInvitationClicked", invitation);
    },
    deleteInvitationClicked(invitation) {
      this.$emit("deleteInvitationClicked", invitation, true);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 24px;
}

::v-deep .v-data-table {
  .v-data-table__wrapper table {
    thead {
      tr {
        th {
          color: var(--azena-black) !important;
        }
      }
    }
  }
}
</style>
