import { commonMutationTypes } from "../mutationTypes";

const { ADD_MESSAGE_OBJ } = commonMutationTypes;

const commitMessage = (commit, messages) => {
  if (messages.success.length === 0 && messages.error.length === 0) {
    return;
  }
  commit(ADD_MESSAGE_OBJ, messages, { root: true });
};

const buildAndCommitMessage = (commit, data) => {
  const messages = buildMessageObj(data);
  commitMessage(commit, messages);
};

const buildMessageObj = (data) => {
  const messages = data["messages"];
  const errors = data["errors"];
  for (const field in errors) {
    if (errors.hasOwnProperty(field)) {
      messages.error.push(errors[field].reason);
    }
  }
  return messages;
};

export { commitMessage, buildAndCommitMessage };
