<template>
  <v-container>
    <template v-if="errorStatusCode">
      <v-row>
        <v-col class="error-status"
          ><Error :statusCode="errorStatusCode"
        /></v-col>
      </v-row>
    </template>
    <template v-else-if="companyInformation">
      <v-row class="mb-16">
        <v-col>
          <h1>{{ $t("notificationSettingsTitle") }}</h1>
          <p>{{ $t("notificationSettingsSubtitle") }}</p>
        </v-col>
      </v-row>
      <template v-if="featureFlags.FEATURE_PURCHASE_SALES_EMAILS">
        <v-row class="mb-6" v-if="isPurchaseEnabled || isSalesEnabled">
          <v-col>
            <h2>{{ $t("billingNotificationsHeader") }}</h2>
          </v-col>
        </v-row>
        <v-form ref="appPurchaseEmailForm" v-if="isPurchaseEnabled">
          <v-row class="mb-4">
            <v-col>
              <h3>{{ $t("appPurchasesHeader") }}</h3>
              <p>{{ $t("appPurchasesDescription") }}</p>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col>
              <CDInput
                name="appPurchaseEmailInput"
                v-model="companyInformation.appPurchaseEmail"
                :maxlength="254"
                :disabled="!this.canManageCompanyDetails"
                :label="$t('appPurchasePlaceholder')"
                :rules="validation.email"
              />
            </v-col>
            <v-col>
              <CDButton
                v-if="this.canManageCompanyDetails"
                name="appPurchaseEmailBtn"
                color="primary"
                large
                class="aligned-button"
                :loading="isAppPurchaseBtnDisabled"
                @click="updateAppPurchaseEmail"
                >{{ $t("save") }}
              </CDButton>
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="appSalesEmailForm" v-if="isSalesEnabled">
          <v-row class="mb-4">
            <v-col>
              <h3>{{ $t("appSalesHeader") }}</h3>
              <p>{{ $t("appSalesDescription") }}</p>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col>
              <CDInput
                name="appSalesEmailInput"
                v-model="companyInformation.appSalesEmail"
                :maxlength="254"
                :disabled="!this.canManageCompanyDetails"
                :label="$t('appSalesPlaceholder')"
                :rules="validation.email"
              />
            </v-col>
            <v-col>
              <CDButton
                v-if="this.canManageCompanyDetails"
                name="appSalesEmailBtn"
                color="primary"
                large
                class="aligned-button"
                :loading="isAppSalesBtnDisabled"
                @click="updateAppSalesEmail"
                >{{ $t("save") }}
              </CDButton>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-form ref="offerRequestEmailForm" v-if="isSalesEnabled">
        <v-row>
          <v-col>
            <h2>{{ $t("sellerEmailHeader") }}</h2>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <p>{{ $t("sellerEmailDescription") }}</p>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <CDInput
              name="sellerEmailInput"
              v-model="sellerEmail"
              :maxlength="254"
              :disabled="!this.canManageCompanyDetails"
              :label="$t('sellerEmailPlaceholder')"
              :rules="validation.email"
              @keydown.enter.prevent="updateOfferRequestEmail"
            />
          </v-col>
          <v-col>
            <CDButton
              v-if="this.canManageCompanyDetails"
              name="offerEmailBtn"
              color="primary"
              large
              class="aligned-button"
              :loading="isOfferEmailBtnDisabled"
              @click="updateOfferRequestEmail"
              >{{ $t("save") }}
            </CDButton>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-container>
</template>

<style lang="scss" scoped>
.aligned-button {
  margin-top: 4px;
}
</style>

<script>
import Error from "./Error";
import { mapState } from "vuex";
import { accountActionTypes, ecosystemActionTypes } from "@/store/actionTypes";
import {
  ACCOUNT_COMPANY_NAMESPACE,
  ACCOUNT_NAMESPACE,
  ECOSYSTEM_NAMESPACE,
} from "@/store/namespaces";
import { VContainer, VRow, VCol, VForm } from "vuetify/lib";
import { companyTypes } from "@/utils/enums";

export default {
  name: "NotificationSettings",
  components: {
    Error,
    VContainer,
    VRow,
    VCol,
    VForm,
  },
  computed: {
    ...mapState(ACCOUNT_COMPANY_NAMESPACE, [
      "errorStatusCode",
      "companyInformation",
    ]),
    ...mapState({
      featureFlags: "featureFlags",
    }),
    isPurchaseEnabled() {
      return (
        this.companyInformation &&
        this.companyInformation.companyTypes &&
        this.companyInformation.companyTypes.includes(companyTypes.INTEGRATOR)
      );
    },
    isSalesEnabled() {
      return (
        this.companyInformation &&
        this.companyInformation.companyTypes &&
        this.companyInformation.companyTypes.includes(companyTypes.DEVELOPER)
      );
    },
    sellerEmail: {
      get: function () {
        return this.companyInformation.sellerEmails
          ? this.companyInformation.sellerEmails[0]
          : "";
      },
      set: function (value) {
        this.companyInformation.sellerEmails[0] = value;
      },
    },
  },
  data() {
    return {
      canManageCompanyDetails: false,
      isAppPurchaseBtnDisabled: false,
      isAppSalesBtnDisabled: false,
      isOfferEmailBtnDisabled: false,
      validation: {
        email: [
          (value) => {
            if(value === ""){
              return true;
            }
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) || this.$t("validation.EMAIL_NOT_WELL_FORMED")
            );
          },
        ],
      },
    };
  },
  methods: {
    updateAppPurchaseEmail() {
      if (!this.$refs.appPurchaseEmailForm.validate()) return;

      this.isAppPurchaseBtnDisabled = true;

      return this.$store
        .dispatch(
          ECOSYSTEM_NAMESPACE +
            "/" +
            ecosystemActionTypes.UPDATE_APP_PURCHASE_EMAIL,
          {
            companyId: this.companyInformation.companyId,
            email: this.companyInformation.appPurchaseEmail,
          }
        )
        .finally(() => {
          this.isAppPurchaseBtnDisabled = false;
        });
    },
    updateAppSalesEmail() {
      if (!this.$refs.appSalesEmailForm.validate()) return;

      this.isAppSalesBtnDisabled = true;

      return this.$store
        .dispatch(
          ECOSYSTEM_NAMESPACE +
            "/" +
            ecosystemActionTypes.UPDATE_APP_SALES_EMAIL,
          {
            companyId: this.companyInformation.companyId,
            email: this.companyInformation.appSalesEmail,
          }
        )
        .finally(() => {
          this.isAppSalesBtnDisabled = false;
        });
    },
    updateOfferRequestEmail() {
      if (!this.$refs.offerRequestEmailForm.validate()) return;

      this.isOfferEmailBtnDisabled = true;

      return this.$store
        .dispatch(
          ECOSYSTEM_NAMESPACE + "/" + ecosystemActionTypes.ADD_SELLER_EMAIL,
          {
            companyId: this.companyInformation.companyId,
            sellerEmail: this.companyInformation.sellerEmails[0],
          }
        )
        .finally(() => {
          this.isOfferEmailBtnDisabled = false;
        });
    },
  },
  beforeMount() {
    this.$store.dispatch(
      ACCOUNT_COMPANY_NAMESPACE + "/" + accountActionTypes.FETCH_ACCOUNT_COMPANY
    );

    this.$store.dispatch(
      ACCOUNT_NAMESPACE + "/" + accountActionTypes.GET_PERMISSIONS_FROM_TOKEN
    );

    this.canManageCompanyDetails =
      this.$store.state.account.canManageCompanyDetails;
  },
};
</script>
