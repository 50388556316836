import axios from "axios";

/**
 * central module to interface with the backend API
 * This was purposely put into extra module in order to mock it easily in component tests
 */

const getUsersForOwnCompany = () => {
  return axios.get("/rest/account/company/users");
};

const getUserInvitationsForOwnCompany = () => {
  return axios.get("/rest/account/company/users/invitations");
};

const getAccountUser = () => {
  return axios.get("/rest/account/user");
};

const updateAccountUser = (body) => {
  return axios.put("/rest/account/user", body);
};

const updateUserPermissionGroupsCompany = (userName, companyId, body) => {
  return axios.put(`/rest/account/users/${userName}/permissions`, body);
};

const sendInvitationToJoinCompany = (body) => {
  return axios.post("/rest/account/company/users/invite", body);
};

const getAccountCompany = () => {
  return axios.get("/rest/account/company");
};

const deleteUserInOwnCompany = (userName) => {
  return axios.delete(`/rest/account/users/${userName}`);
};

const deleteSelf = () => {
  return axios.delete(`/rest/account/user`);
};

const resendInvitation = (userName) => {
  return axios.put(`/rest/account/company/users/invitations/${userName}`, {});
};

const updateCompanyFriendlyName = (companyId, friendlyName) => {
  return axios.patch(`/rest/companies/${companyId}/friendly-name`, {
    companyId,
    friendlyName,
  });
};

const addSellerEmail = (companyId, sellerEmail) => {
  return axios.patch(`/rest/companies/${companyId}/seller-email`, {
    companyId,
    sellerEmail,
  });
};

const togglePreApprovedAppsFeature = (companyId, enabled) => {
  return axios.post(`/rest/companies/${companyId}/features/pre-approved-apps`, {
    companyId,
    isEnabled: enabled,
  });
};

const updateAppPurchaseEmail = (companyId, email) => {
  return axios.patch(`/rest/companies/${companyId}/purchase-email`, {
    companyId,
    appPurchaseEmail: email,
  });
};

const updateAppSalesEmail = (companyId, email) => {
  return axios.patch(`/rest/companies/${companyId}/sales-email`, {
    companyId,
    appSalesEmail: email,
  });
};

const getCountries = () => {
  return axios.get(`/rest/public/countries/registration`);
};

export default {
  getUsersForOwnCompany,
  getUserInvitationsForOwnCompany,
  getAccountUser,
  updateAccountUser,
  updateUserPermissionGroupsCompany,
  sendInvitationToJoinCompany,
  getAccountCompany,
  deleteUserInOwnCompany,
  deleteSelf,
  resendInvitation,
  updateCompanyFriendlyName,
  updateAppPurchaseEmail,
  updateAppSalesEmail,
  togglePreApprovedAppsFeature,
  getCountries,
  addSellerEmail,
};
