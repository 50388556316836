export function sortUsers(users) {
  return users.sort((userA, userB) => {
    const comparedLastname = userA["lastName"].localeCompare(userB["lastName"]);
    return comparedLastname !== 0
      ? comparedLastname
      : userA["firstName"].localeCompare(userB["firstName"]);
  });
}

export function sortCompanies(companies) {
  return companies.sort((companyA, companyB) => {
    const comparedName = companyA["companyName"].localeCompare(
      companyB["companyName"]
    );
    return comparedName !== 0
      ? comparedName
      : companyA["companyId"].localeCompare(companyB["companyId"]);
  });
}

export function sortInvitations(invitations) {
  return invitations.sort((invitationA, invitationB) =>
    invitationA["email"].localeCompare(invitationB["email"])
  );
}

export function sortCountries(countries) {
  return countries.sort((a, b) => (a.name >= b.name ? 1 : -1));
}
