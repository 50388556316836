const userMutationTypes = {
  UPDATE_PERMISSIONS_SUCCESS: "UPDATE_PERMISSIONS_SUCCESS",
};

const accountMutationTypes = {
  FETCH_ACCOUNT_USER_SUCCESS: "FETCH_ACCOUNT_USER_SUCCESS",
  UPDATE_ACCOUNT_USER_SUCCESS: "UPDATE_ACCOUNT_USER_SUCCESS",
  SET_USERS: "SET_USERS",
};

const accountCompanyMutationTypes = {
  RESEND_INVITATION_SUCCESS: "RESEND_INVITATION_SUCCESS",
  SEND_INVITATION_SUCCESS: "SEND_INVITATION_SUCCESS",
  UPDATE_INVITATION_SUCCESS: "UPDATE_INVITATION_SUCCESS",
  SET_INVITATIONS: "SET_INVITATIONS",
  REMOVE_SINGLE_USER: "REMOVE_SINGLE_USER",
  REMOVE_SINGLE_INVITATION: "REMOVE_SINGLE_INVITATION",
};

const companyMutationTypes = {
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
};
const commonMutationTypes = {
  API_ERROR: "API_ERROR",
  SET_DATA: "SET_DATA",
  ADD_MESSAGE_OBJ: "ADD_MESSAGE_OBJ",
  REMOVE_FIRST_MESSAGE_OBJ: "REMOVE_FIRST_MESSAGE_OBJ",
  RESET_STATE: "RESET_STATE",
  LOAD_COUNTRIES_SUCCESS: "LOAD_COUNTRIES_SUCCESS",
};

const ecosystemMutationTypes = {
  SET_COMPANIES: "SET_COMPANIES",
  SET_USERS: "SET_USERS",
  SET_USER: "SET_USER",
  SET_COMPANY: "SET_COMPANY",
  SET_INVITATIONS: "SET_INVITATIONS",
  REMOVE_SINGLE_USER: "REMOVE_SINGLE_USER",
  REMOVE_SINGLE_INVITATION: "REMOVE_SINGLE_INVITATION",
  SET_COMPANY_INFORMATION: "SET_COMPANY_INFORMATION",
};

export {
  accountMutationTypes,
  accountCompanyMutationTypes,
  userMutationTypes,
  companyMutationTypes,
  commonMutationTypes,
  ecosystemMutationTypes,
};
