<template>
  <v-container>
    <div v-if="!canManageCompanyUsers" class="not-authorized">
      <Error v-bind:statusCode="403" />
    </div>
    <template v-else>
      <v-row class="mb-16">
        <v-col>
          <h1>{{ $t("userManagementTitle") }}</h1>
          <p>{{ $t("userManagementSubtitle") }}</p>
        </v-col>
        <v-col class="text-right invite-user-btn-container">
          <CDButton
            v-if="canInviteCompanyUsers"
            name="inviteUserButton"
            color="primary"
            large
            @click="openInvitationModal()"
            >{{ $t("userManagementInvitationButton") }}
          </CDButton>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>
          <UserInvitations
            :is-loading="!doneLoading.invitations"
            :can-edit="canInviteCompanyUsers"
            :invitations="sortItemsByOrderDeskPermission(invitations)"
            @editPermissionGroupsClicked="openPermissionGroupsModal"
            @resendInvitationClicked="resendInvitation"
            @deleteInvitationClicked="deleteUserOrInvitation"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Users
            :is-loading="!doneLoading.users"
            :users="sortItemsByOrderDeskPermission(users)"
            @editPermissionGroupsClicked="openPermissionGroupsModal"
            @deleteUserClicked="deleteUserOrInvitation"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<style lang="scss" scoped>
.invite-user-btn-container {
  margin-top: 8px;
}
</style>

<script>
import Error from "./Error";
import { mapState } from "vuex";
import { VContainer, VRow, VCol } from "vuetify/lib";
import {
  accountActionTypes,
  accountCompanyUsersActionTypes,
  userActionTypes,
} from "@/store/actionTypes";
import PermissionGroupsModal from "./Modals/PermissionGroupsModal";
import InvitationModal from "./Modals/InvitationModal";
import { translatedMessages } from "@/utils/mixins";

import { userObjectTypes, permissionNames } from "@/utils/enums";
import {
  ACCOUNT_COMPANY_NAMESPACE,
  ACCOUNT_COMPANY_USERS_NAMESPACE,
  ACCOUNT_NAMESPACE,
  EDIT_USER_AND_INVITATION_NAMESPACE,
} from "@/store/namespaces";
import UserInvitations from "@/views/Components/UserInvitations";
import Users from "@/views/Components/Users";

const { USER, INVITATION } = userObjectTypes;

export default {
  name: "ManageUsers",
  components: {
    UserInvitations,
    Users,
    Error,
    VContainer,
    VRow,
    VCol,
  },
  mixins: [translatedMessages],
  data() {
    return {
      ...resetState(this),
      USER,
      INVITATION,
    };
  },
  computed: {
    ...mapState(ACCOUNT_COMPANY_NAMESPACE, ["companyInformation"]),
    ...mapState(ACCOUNT_COMPANY_USERS_NAMESPACE, [
      "users",
      "invitations",
      "errors",
      "doneLoading",
    ]),
    ...mapState(ACCOUNT_NAMESPACE, [
      "canManageCompanyUsers",
      "canInviteCompanyUsers",
    ]),
  },
  methods: {
    sortItemsByOrderDeskPermission(items) {
      return Array.from(items).sort(
        (a, b) =>
          a.permissions[permissionNames.ORDER_DESK_MANAGER] -
          b.permissions[permissionNames.ORDER_DESK_MANAGER]
      );
    },
    getUsersForCompany() {
      this.$store.dispatch(
        ACCOUNT_COMPANY_USERS_NAMESPACE +
          "/" +
          accountActionTypes.GET_USERS_FOR_OWN_COMPANY
      );
    },
    getInvitationsForCompany() {
      this.$store.dispatch(
        ACCOUNT_COMPANY_USERS_NAMESPACE +
          "/" +
          accountCompanyUsersActionTypes.GET_INVITATIONS_FOR_OWN_COMPANY
      );
    },
    savePermissionGroups(user, permissions, isInvitation) {
      return this.$store.dispatch(
        EDIT_USER_AND_INVITATION_NAMESPACE +
          "/" +
          userActionTypes.UPDATE_USER_PERMISSION_GROUPS_COMPANY,
        { user, permissions, isInvitation }
      );
    },
    openInvitationModal() {
      if (!this.errors.permissionList) {
        this.$modal.show(
          InvitationModal,
          { companyTypes: this.companyTypes },
          {
            height: "auto",
            width: "1200px",
            clickToClose: false,
          }
        );
      }
    },
    openPermissionGroupsModal(user) {
      const isInvitation =
        user.hasOwnProperty("expirationDate") && user.hasOwnProperty("status");
      this.$modal.show(
        PermissionGroupsModal,
        {
          user,
          companyTypes: this.companyTypes,
          savePermissionGroupsFunction: (permissions) =>
            this.savePermissionGroups(user, permissions, isInvitation),
        },
        {
          height: "auto",
          width: "1200px",
          clickToClose: false,
        }
      );
    },
    resendInvitation(user) {
      return this.$store.dispatch(
        ACCOUNT_COMPANY_USERS_NAMESPACE +
          "/" +
          userActionTypes.RESEND_INVITATION,
        user
      );
    },
    deleteUserOrInvitation(user, isInvitation) {
      return this.$store.dispatch(
        ACCOUNT_COMPANY_USERS_NAMESPACE +
          "/" +
          userActionTypes.DELETE_USER_OR_INVITATION,
        {
          payload: user,
          isInvitation: isInvitation,
        }
      );
    },
    getInformationForAccountCompany() {
      this.$store
        .dispatch(
          ACCOUNT_COMPANY_NAMESPACE +
            "/" +
            accountActionTypes.FETCH_ACCOUNT_COMPANY
        )
        .then(() => {
          Object.assign(this.$data, resetState(this));
        });
    },
  },
  created() {
    this.getUsersForCompany();
    this.getInvitationsForCompany();
    if (this.canInviteCompanyUsers) {
      this.getInformationForAccountCompany();
    }
  },
};

function resetState(component) {
  return {
    companyTypes: component.companyInformation
      ? component.companyInformation.companyTypes
      : [],
  };
}
</script>
