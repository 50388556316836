<template>
  <div class="v-application modal">
    <v-card flat>
      <v-card-title>
        <h2>{{ $t(title) }}</h2>
      </v-card-title>
      <v-card-text>
        <form>
          <p v-for="text in translatedTextParagraphs" v-bind:key="text">
            {{ text }}
          </p>
          <CDCheckbox
            v-if="isCritical"
            color="primary"
            name="isCriticalActionConfirmed"
            :checked="isActionConfirmed"
            :label="$t('confirmActionAndAgreeWithConsequences')"
            @change="mapCheckboxToState('isActionConfirmed', $event)"
          />
          <p v-if="warning" class="confirmation-warning">
            {{ $t(warning) }}
          </p>
        </form>
      </v-card-text>
      <v-card-actions class="actions">
        <CDButton name="closeButton" large @click="cancelAction">
          {{ $t("cancel") }}
        </CDButton>
        <CDButton
          v-if="confirmClickedFunction"
          name="confirmButton"
          color="primary"
          large
          :disabled="isCritical && !isActionConfirmed"
          @click="confirmAction"
          >{{ $t("confirm") }}
        </CDButton>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { VCard, VCardTitle, VCardText, VCardActions } from "vuetify/lib";

export default {
  name: "ConfirmationModal",
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
  },
  props: [
    "title",
    "translatedTextParagraphs",
    "warning",
    "confirmClickedFunction",
    "isCritical",
  ],
  data() {
    return {
      isActionConfirmed: false,
    };
  },
  methods: {
    cancelAction() {
      this.$emit("close");
    },
    confirmAction() {
      if (!this.isCritical || this.isActionConfirmed) {
        this.confirmClickedFunction();
        this.$emit("close");
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0; // For Safari
      }
    },
    mapCheckboxToState(field, checked) {
      this[field] = checked;
    },
  },
};
</script>
