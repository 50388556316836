import Vue from "vue";
import Router from "vue-router";
import MyProfile from "./views/MyProfile";
import MyCompany from "./views/MyCompany";
import ManageUsers from "./views/ManageUsers.vue";
import Error from "./views/Error";
import NotificationSettings from "@/views/NotificationSettings";

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: { name: "my-profile" },
  },
  {
    path: "/legal/*",
    // ignore and pass on to server
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: MyProfile,
    meta: {
      activeTab: "profile",
    },
  },
  {
    path: "/my-company",
    name: "my-company",
    component: MyCompany,
    meta: {
      activeTab: "company",
    },
  },
  {
    path: "/manage-users",
    name: "manage-users",
    component: ManageUsers,
    meta: {
      activeTab: "management",
    },
  },
  {
    path: "/notification-settings",
    name: "notification-settings",
    component: NotificationSettings,
    meta: {
      activeTab: "notificationSettings",
    },
  },
];

// selected only if all routes above do not match
routes.push({
  path: "/*",
  name: "error",
  component: Error,
  props: { statusCode: 404 },
});

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
