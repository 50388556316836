<template>
  <v-container>
    <template v-if="errorStatusCode">
      <v-row>
        <v-col class="error-status"
          ><Error :statusCode="errorStatusCode"
        /></v-col>
      </v-row>
    </template>
    <template v-else-if="companyInformation">
      <v-row class="mb-16">
        <v-col>
          <h1>{{ companyInformation.companyName }}</h1>
          <p>{{ $t("myCompanySubtitle") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>{{ $t("companyId") }}</h2>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>{{ $t("companyIdSubtitle") }}</v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>
          <CompanyId :company="companyInformation" />
        </v-col>
      </v-row>
      <v-form ref="companyFriendlyNameForm">
        <v-row>
          <v-col>
            <h2>{{ $t("brandDetails") }}</h2>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <p>{{ $t("companyFriendlyName") }}</p>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <CDInput
              name="companyFriendlyNameInput"
              v-model="companyFriendlyNameOrName"
              :maxlength="160"
              :rules="validation.companyFriendlyName"
              :disabled="!this.canManageCompanyDetails"
            />
          </v-col>
          <v-col>
            <CDButton
              v-if="this.canManageCompanyDetails"
              name="saveButton"
              color="primary"
              large
              class="aligned-button"
              :disabled="isCompanyFriendlyNameBtnDisabled"
              @click="updateCompanyFriendlyName"
              >{{ $t("save") }}
            </CDButton>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="mb-4">
        <v-col>
          <h2>{{ $t("legalDetails") }}</h2>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <div class="update-legal-info">
            <v-icon color="info">$statusinfo</v-icon>
            <a
              href="https://support.azena.com/hc/en-us/requests/new"
              target="_blank"
              >{{ $t("legalDetailsUpdate") }}</a
            >
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <h4 class="mb-2">{{ $t("companyNameInput") }}</h4>
          <p>{{ companyInformation.companyName }}</p>
        </v-col>
        <v-col>
          <h4 class="mb-2">{{ $t("companyTypes") }}</h4>
          <p>{{ companyTypes }}</p>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <h4 class="mb-2">{{ $t("taxId") }}</h4>
          <p>{{ companyInformation.taxId }}</p>
        </v-col>
        <v-col>
          <h4 class="mb-2">{{ $t("companyCountryInput") }}</h4>
          <p>{{ companyInformation.companyCountry }}</p>
        </v-col>
      </v-row>
      <v-row v-if="companyInformation.website" class="mb-8">
        <v-col>
          <h4 class="mb-2">{{ $t("website") }}</h4>
          <p>{{ companyInformation.website }}</p>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <h4 class="mb-2">{{ $t("registeredBusinessAddress") }}</h4>
          <p>
            {{ businessAddressStreetOrEmpty }}
            {{ businessAddressHouseNumberOrEmpty }}<br />
            {{ businessAddressPostalCodeOrEmpty }}
            {{ businessAddressCityOrEmpty }}
            {{ businessAddressStateOrEmpty }}
            {{ businessAddressRegionOrEmpty }}<br />
          </p>
        </v-col>
        <v-col>
          <h4 class="mb-2">{{ $t("registeredBillingAddress") }}</h4>
          <p>
            {{ billingAddressStreetOrEmpty }}
            {{ billingAddressHouseNumberOrEmpty }}<br />
            {{ billingAddressPostalCodeOrEmpty }}
            {{ billingAddressCityOrEmpty }}
            {{ billingAddressStateOrEmpty }}
            {{ billingAddressRegionOrEmpty }}<br />
          </p>
        </v-col>
      </v-row>
      <!--
      <v-row>
        <v-col>
          <h2>{{ $t("appsPreapproval") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CDToggle
            v-model="companyInformation.isPreApprovedAppsEnabled"
            :label="$t('appsPreapprovalRequired')"
            :disabled="!this.canManageCompanyDetails"
            @change="confirmRequireAppsPreApproval($event)"
          />
          <p>{{ $t("appsPreapprovalDescription") }}</p>
        </v-col>
      </v-row>
      -->
    </template>
  </v-container>
</template>

<style lang="scss" scoped>
::v-deep .update-legal-info {
  display: flex;
  column-gap: 8px;

  ::v-deep a {
    color: var(--azena-info) !important;
  }
}
.require-apps-preapproval {
  margin-bottom: 10px;
}

.aligned-button {
  margin-top: 4px;
}
</style>

<script>
import Error from "./Error";
import { mapState } from "vuex";
import { accountActionTypes, ecosystemActionTypes } from "@/store/actionTypes";
import {
  ACCOUNT_COMPANY_NAMESPACE,
  ACCOUNT_NAMESPACE,
  ECOSYSTEM_NAMESPACE,
} from "@/store/namespaces";
import CompanyId from "./Components/CompanyId.vue";
import AppsPreapprovalModal from "@/views/Modals/AppsPreapprovalModal";
import { VContainer, VRow, VCol, VIcon, VForm } from "vuetify/lib";
import { companyTypes, validateMixin } from "@/utils/mixins";

export default {
  name: "MyCompany",
  components: {
    Error,
    CompanyId,
    VContainer,
    VRow,
    VCol,
    VIcon,
    VForm,
  },
  mixins: [companyTypes, validateMixin],
  computed: {
    ...mapState(ACCOUNT_COMPANY_NAMESPACE, [
      "errorStatusCode",
      "companyInformation",
    ]),
    companyTypes() {
      return this.companyInformation && this.companyInformation.companyTypes
        ? this.formatCompanyTypes(this.companyInformation.companyTypes)
        : "";
    },
    companyFriendlyNameOrName: {
      get: function () {
        return (
          this.companyInformation.friendlyName ||
          this.companyInformation.companyName
        );
      },
      set: function (value) {
        this.companyInformation.friendlyName = value;
      },
    },
    businessAddressStreetOrEmpty() {
      return this.companyInformation.businessAddress
        ? this.companyInformation.businessAddress.street
        : "";
    },
    businessAddressHouseNumberOrEmpty() {
      return this.companyInformation.businessAddress
        ? this.companyInformation.businessAddress.houseNumber
        : "";
    },
    businessAddressPostalCodeOrEmpty() {
      return this.companyInformation.businessAddress
        ? this.companyInformation.businessAddress.postalCode
        : "";
    },
    businessAddressCityOrEmpty() {
      return this.companyInformation.businessAddress
        ? this.companyInformation.businessAddress.city
        : "";
    },
    businessAddressStateOrEmpty() {
      return this.companyInformation.businessAddress
        ? this.companyInformation.businessAddress.state
        : "";
    },
    businessAddressRegionOrEmpty() {
      return this.companyInformation.businessAddress
        ? this.companyInformation.businessAddress.region
        : "";
    },
    billingAddressStreetOrEmpty() {
      return this.companyInformation.billingAddress
        ? this.companyInformation.billingAddress.street
        : "";
    },
    billingAddressHouseNumberOrEmpty() {
      return this.companyInformation.billingAddress
        ? this.companyInformation.billingAddress.houseNumber
        : "";
    },
    billingAddressPostalCodeOrEmpty() {
      return this.companyInformation.billingAddress
        ? this.companyInformation.billingAddress.postalCode
        : "";
    },
    billingAddressCityOrEmpty() {
      return this.companyInformation.billingAddress
        ? this.companyInformation.billingAddress.city
        : "";
    },
    billingAddressStateOrEmpty() {
      return this.companyInformation.billingAddress
        ? this.companyInformation.billingAddress.state
        : "";
    },
    billingAddressRegionOrEmpty() {
      return this.companyInformation.billingAddress
        ? this.companyInformation.billingAddress.region
        : "";
    },
    sellerEmail: {
      get: function () {
        return this.companyInformation.sellerEmails
          ? this.companyInformation.sellerEmails[0]
          : "";
      },
      set: function (value) {
        this.companyInformation.sellerEmails = value;
      },
    },
  },
  data() {
    return {
      isCompanyFriendlyNameBtnDisabled: false,
      isCompanyBillingEmailBtnDisabled: false,
      isCompanyBillingEmailInvalid: false,
      canManageCompanyDetails: false,
      isSellerEmailInvalid: false,
      isSellerEmailBtnDisabled: false,
      validation: {
        companyFriendlyName: [
          (value) => {
            const pattern =
              "^[\\p{Script_Extensions=Latin}\\p{Zs}\\p{Nd}\\p{Pd}\\p{Pc}()'&$.:,+/*]+$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("validation.INVALID_COMPANY_FRIENDLY_NAME")
            );
          },
        ],
        email: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) || this.$t("validation.EMAIL_NOT_WELL_FORMED")
            );
          },
        ],
      },
    };
  },
  methods: {
    getInformationForAccountCompany() {
      return this.$store.dispatch(
        ACCOUNT_COMPANY_NAMESPACE +
          "/" +
          accountActionTypes.FETCH_ACCOUNT_COMPANY
      );
    },
    isCountry(country) {
      return this.companyInformation.companyCountry
        ? this.companyInformation.companyCountry.toUpperCase() ===
            country.toUpperCase()
        : false;
    },
    updateCompanyFriendlyName() {
      if (!this.$refs.companyFriendlyNameForm.validate()) return;

      this.isCompanyFriendlyNameBtnDisabled = true;
      return this.$store
        .dispatch(
          ECOSYSTEM_NAMESPACE +
            "/" +
            ecosystemActionTypes.UPDATE_COMPANY_FRIENDLY_NAME,
          {
            companyId: this.companyInformation.companyId,
            friendlyName: this.companyFriendlyNameOrName,
          }
        )
        .finally(() => {
          this.isCompanyFriendlyNameBtnDisabled = false;
        });
    },
    confirmRequireAppsPreApproval(checked) {
      this.companyInformation.isPreApprovedAppsEnabled = checked;

      this.$modal.show(
        AppsPreapprovalModal,
        {
          enable: checked,
          cancelClickedFunction: () => {
            this.companyInformation.isPreApprovedAppsEnabled =
              !this.companyInformation.isPreApprovedAppsEnabled;
          },
          confirmClickedFunction: () => {
            this.companyInformation.isPreApprovedAppsEnabled = checked;

            return this.$store.dispatch(
              ECOSYSTEM_NAMESPACE +
                "/" +
                ecosystemActionTypes.UPDATE_COMPANY_PRE_APPROVED_APPS,
              {
                companyId: this.companyInformation.companyId,
                enabled: this.companyInformation.isPreApprovedAppsEnabled,
              }
            );
          },
        },
        {
          height: "auto",
          width: "1200px",
          clickToClose: false,
        }
      );
    },
  },
  created() {
    return this.getInformationForAccountCompany();
  },
  beforeMount() {
    this.$store.dispatch(
      ACCOUNT_NAMESPACE + "/" + accountActionTypes.GET_PERMISSIONS_FROM_TOKEN
    );
    this.canManageCompanyDetails =
      this.$store.state.account.canManageCompanyDetails;
  },
};
</script>
