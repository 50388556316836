<template>
  <div class="v-application modal">
    <v-card flat>
      <v-card-title>
        <h2>{{ $t(title) }}</h2>
      </v-card-title>
      <v-card-text>
        <p>{{ $t(description) }}</p>
      </v-card-text>
      <v-card-actions class="actions">
        <CDButton name="closeButton" large @click="cancelAction">
          {{ $t("cancel") }}
        </CDButton>
        <CDButton
          name="confirmButton"
          color="primary"
          large
          @click="confirmAction"
          >{{ $t(button) }}
        </CDButton>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { VCard, VCardTitle, VCardText, VCardActions } from "vuetify/lib";

export default {
  name: "AppsPreapprovalModal",
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
  },
  props: ["enable", "cancelClickedFunction", "confirmClickedFunction"],
  computed: {
    title() {
      return this.enable
        ? "appsPreapprovalModal.enable.title"
        : "appsPreapprovalModal.disable.title";
    },
    description() {
      return this.enable
        ? "appsPreapprovalModal.enable.description"
        : "appsPreapprovalModal.disable.description";
    },
    button() {
      return this.enable ? "enable" : "disable";
    },
  },
  methods: {
    cancelAction() {
      this.cancelClickedFunction();
      this.$emit("close");
    },
    confirmAction() {
      this.confirmClickedFunction();
      this.$emit("close");
    },
  },
};
</script>
